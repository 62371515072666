var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "div",
                    [
                      _c("h2", { staticClass: "d-inline" }, [
                        _vm._v(
                          "Project: " +
                            _vm._s(_vm.$session.get("projectname")) +
                            " "
                        ),
                        _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value:
                                "<p>1. Add categories</p><p>2. Add wells</p><p>3. Add AFE using well + category</p><p>4. Assign vendor services</p><p>5. Assign cost codes to vendors</p><p>6. Assign well site supervisors to project</p>",
                              expression:
                                "'<p>1. Add categories</p><p>2. Add wells</p><p>3. Add AFE using well + category</p><p>4. Assign vendor services</p><p>5. Assign cost codes to vendors</p><p>6. Assign well site supervisors to project</p>'"
                            }
                          ],
                          staticClass: "ml-2 fa fa fa-question-circle"
                        })
                      ]),
                      _c(
                        "router-link",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "btn btn-primary float-right mr-2",
                          attrs: {
                            to: {
                              name: "Register WSS",
                              query: { pid: _vm.$route.params.id }
                            },
                            target: "_blank",
                            variant: "success",
                            title: "Generate/Register WSS"
                          },
                          on: {
                            click: function($event) {
                              _vm.error = false
                            }
                          }
                        },
                        [
                          _vm._v("\n            Generate/Register WSS "),
                          _c("i", { staticClass: "fa fa-external-link" })
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "btn btn-primary float-right mr-2",
                          attrs: {
                            to: {
                              name: "Register Vendor",
                              query: { id: _vm.profile.wtsid }
                            },
                            target: "_blank",
                            variant: "success",
                            title: "Generate/Register Vendor"
                          },
                          on: {
                            click: function($event) {
                              _vm.error = false
                            }
                          }
                        },
                        [
                          _vm._v("\n            Generate/Register Vendor "),
                          _c("i", { staticClass: "fa fa-external-link" })
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.addWssVendor",
                              modifiers: { addWssVendor: true }
                            },
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "float-right mr-2",
                          attrs: {
                            variant: "success",
                            title:
                              "Invite your vendor (real Or psudo) by clicking here. Must have email of that vendor."
                          },
                          on: {
                            click: function($event) {
                              _vm.error = false
                            }
                          }
                        },
                        [
                          _vm._v("Invite WSS/Vendor To Project "),
                          _c("i", { staticClass: "fa fa-plus" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "clearfix mt-3 mb-4" }),
                  _c(
                    "b-tabs",
                    [
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { staticClass: "fa fa-folder" }),
                            _vm._v(" Categories\n            ")
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("h4", { staticClass: "d-inline" }, [
                                    _vm._v("Categories")
                                  ]),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.addCat",
                                          modifiers: { addCat: true }
                                        }
                                      ],
                                      staticClass: "pull-right",
                                      attrs: { variant: "success" },
                                      on: {
                                        click: function($event) {
                                          return _vm.clearCat()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(" Add Category "),
                                      _c("i", { staticClass: "fa fa-plus" })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-client-table", {
                            staticClass: "statusTable",
                            attrs: {
                              columns: _vm.columnsCat,
                              data: _vm.wtsCatData,
                              options: _vm.optionsCat,
                              theme: _vm.theme,
                              id: "dataTable"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "status",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    props.row.status === 0
                                      ? _c("span", [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-success w-100"
                                            },
                                            [_vm._v("Active")]
                                          )
                                        ])
                                      : _vm._e(),
                                    props.row.status !== 0
                                      ? _c("span", [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-danger w-100"
                                            },
                                            [_vm._v("Inactive")]
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                }
                              },
                              {
                                key: "edit",
                                fn: function(props) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal.editCat",
                                              modifiers: { editCat: true }
                                            }
                                          ],
                                          staticClass: "btn-sm",
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.sendCatInfo(
                                                props.row.pcid,
                                                props.row.projectid,
                                                props.row.status,
                                                props.row.description,
                                                props.row.category
                                              )
                                            }
                                          }
                                        },
                                        [_c("i", { staticClass: "fa fa-edit" })]
                                      )
                                    ],
                                    1
                                  )
                                }
                              }
                            ])
                          })
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { staticClass: "fa fa-tint" }),
                            !_vm.$route.meta.is_resource
                              ? _c("span", [_vm._v(" Asset")])
                              : _c("span", [
                                  _vm._v("\n                Operations")
                                ])
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("h4", { staticClass: "d-inline" }, [
                                    !_vm.$route.meta.is_resource
                                      ? _c("span", [_vm._v("Well")])
                                      : _c("span", [_vm._v("Operations")])
                                  ]),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.addWell",
                                          modifiers: { addWell: true }
                                        }
                                      ],
                                      staticClass: "float-right",
                                      attrs: { variant: "success" },
                                      on: { click: _vm.clearWellForm }
                                    },
                                    [
                                      _vm._v(" Add "),
                                      !_vm.$route.meta.is_resource
                                        ? _c("span", [_vm._v("Wells ")])
                                        : _c("span", [_vm._v("Operations ")]),
                                      _c("i", { staticClass: "fa fa-plus" })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-client-table",
                            {
                              staticClass: "statusTable wellTable no-overflow",
                              attrs: {
                                stacked: "",
                                columns: _vm.columns,
                                data: _vm.wtsWellData,
                                options: _vm.options,
                                theme: _vm.theme,
                                id: "dataTable"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "status",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      props.row.status === 0
                                        ? _c("span", [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-success w-100"
                                              },
                                              [_vm._v("Active")]
                                            )
                                          ])
                                        : _vm._e(),
                                      props.row.status !== 0
                                        ? _c("span", [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-danger w-100"
                                              },
                                              [_vm._v("Inactive")]
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  }
                                },
                                {
                                  key: "uwi",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      props.row.uwi
                                        ? _c(
                                            "span",
                                            { staticClass: "clearfix" },
                                            [
                                              _c("strong", [_vm._v("#1")]),
                                              _vm._v(
                                                " " + _vm._s(props.row.uwi)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      props.row.uwi_2
                                        ? _c(
                                            "span",
                                            { staticClass: "clearfix" },
                                            [
                                              _c("strong", [_vm._v("#2")]),
                                              _vm._v(
                                                " " + _vm._s(props.row.uwi_2)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      props.row.uwi_3
                                        ? _c(
                                            "span",
                                            { staticClass: "clearfix" },
                                            [
                                              _c("strong", [_vm._v("#3")]),
                                              _vm._v(
                                                " " + _vm._s(props.row.uwi_3)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  }
                                },
                                {
                                  key: "wellstatus",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        _vm._l(_vm.wtsCatData, function(
                                          table,
                                          index
                                        ) {
                                          return _c(
                                            "table",
                                            {
                                              staticClass:
                                                "d-inline afe-status-table"
                                            },
                                            [
                                              _c("thead", [
                                                _c(
                                                  "th",
                                                  { attrs: { colspan: "2" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(table.category)
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c("tbody", [
                                                _vm.checkForAfe(
                                                  props.row.pwid,
                                                  table.pcid
                                                ) === true
                                                  ? _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass: "px-0",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.startOrEnd(
                                                                1,
                                                                table.pcid,
                                                                props.row.pwid
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("datepicker", {
                                                            staticClass:
                                                              "well-datepicker",
                                                            attrs: {
                                                              format:
                                                                "yyyy-MM-dd",
                                                              value: _vm.getDate(
                                                                props.row.pwid,
                                                                table.pcid,
                                                                1
                                                              ),
                                                              placeholder:
                                                                "Start Date"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.updateAfeDates
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass: "px-0",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.startOrEnd(
                                                                2,
                                                                table.pcid,
                                                                props.row.pwid
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("datepicker", {
                                                            staticClass:
                                                              "well-datepicker",
                                                            attrs: {
                                                              format:
                                                                "yyyy-MM-dd",
                                                              value: _vm.getDate(
                                                                props.row.pwid,
                                                                table.pcid,
                                                                2
                                                              ),
                                                              placeholder:
                                                                "End Date"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.updateAfeDates
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ])
                                                  : _c("tr", [
                                                      _c(
                                                        "td",
                                                        { staticClass: "px-0" },
                                                        [
                                                          _c("datepicker", {
                                                            staticClass:
                                                              "disabled-dp well-datepicker",
                                                            attrs: {
                                                              disabled: "",
                                                              placeholder:
                                                                "Start Date"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "td",
                                                        { staticClass: "px-0" },
                                                        [
                                                          _c("datepicker", {
                                                            staticClass:
                                                              "disabled-dp well-datepicker",
                                                            attrs: {
                                                              disabled: "",
                                                              placeholder:
                                                                "End Date"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ])
                                              ])
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ])
                                  }
                                },
                                {
                                  key: "edit",
                                  fn: function(props) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName: "v-b-modal.editWell",
                                                modifiers: { editWell: true }
                                              }
                                            ],
                                            staticClass: "btn-sm",
                                            attrs: { variant: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.sendWellId(
                                                  props.row.pwid
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-edit"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }
                                }
                              ])
                            },
                            [
                              _c("template", { slot: "h__uwi" }, [
                                !_vm.$route.meta.is_resource
                                  ? _c("span", [_vm._v("UWI")])
                                  : _c("span", [_vm._v("Operation")])
                              ]),
                              _c("template", { slot: "h__lsdsurface" }, [
                                !_vm.$route.meta.is_resource
                                  ? _c("span", [_vm._v("Surface Location")])
                                  : _c("span", [_vm._v("Operation Location")])
                              ]),
                              _c("template", { slot: "h__wellstatus" }, [
                                !_vm.$route.meta.is_resource
                                  ? _c("span", [_vm._v("Status Of Well")])
                                  : _c("span", [_vm._v("Status Of Operation")])
                              ])
                            ],
                            2
                          )
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { staticClass: "fa fa-user" }),
                            _vm._v(" Well Site Supervisors\n            ")
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c("b-col", { attrs: { md: "12" } }, [
                                _c("h4", { staticClass: "d-inline" }, [
                                  _vm._v("Well Site Supervisors")
                                ])
                              ])
                            ],
                            1
                          ),
                          _c("v-client-table", {
                            ref: "WssdataTable",
                            staticClass: "statusTable",
                            attrs: {
                              columns: _vm.columnsWssServices,
                              data: _vm.wtsWssData,
                              options: _vm.optionsWssServices,
                              theme: _vm.theme,
                              id: "dataTable"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "status",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    props.row.status === 0
                                      ? _c("span", [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-success w-100"
                                            },
                                            [_vm._v("Active")]
                                          )
                                        ])
                                      : _vm._e(),
                                    props.row.status !== 0
                                      ? _c("span", [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-danger w-100"
                                            },
                                            [_vm._v("Inactive")]
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                }
                              },
                              {
                                key: "fname",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(props.row.fname) +
                                        " " +
                                        _vm._s(props.row.lname) +
                                        "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "edit",
                                fn: function(props) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal.editWSS",
                                              modifiers: { editWSS: true }
                                            }
                                          ],
                                          staticClass: "btn-sm",
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.sendWssInfo(props.row)
                                            }
                                          }
                                        },
                                        [_c("i", { staticClass: "fa fa-edit" })]
                                      )
                                    ],
                                    1
                                  )
                                }
                              },
                              {
                                key: "delete",
                                fn: function(props) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-sm",
                                          attrs: { variant: "danger" },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteWss(props.row)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }
                              }
                            ])
                          })
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { staticClass: "fa fa-user" }),
                            _vm._v(" Vendors\n            ")
                          ]),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("h4", { staticClass: "d-inline" }, [
                                    _vm._v("Vendors")
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group ml-2",
                                      staticStyle: {
                                        width: "200px",
                                        float: "right"
                                      }
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.vendorChange,
                                              expression: "vendorChange"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          staticStyle: {
                                            border: "2px solid #c1c1c1"
                                          },
                                          attrs: { id: "vendorFilter" },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.vendorChange = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              _vm.changeVendors
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: {
                                                selected: "",
                                                value: "all"
                                              }
                                            },
                                            [_vm._v("All Vendors Registered")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "used" } },
                                            [_vm._v("Vendors with services")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "unused" } },
                                            [_vm._v("Vendors without services")]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm.$refs.vendorTable
                                    ? _c(
                                        "vue-json-to-csv",
                                        {
                                          staticClass:
                                            "btn float-right btn-md btn-blue",
                                          attrs: {
                                            labels: {
                                              email: { title: "Email" },
                                              fname: { title: "First Name" },
                                              lname: { title: "Last Name" },
                                              company_name: {
                                                title: "Company Name"
                                              },
                                              creation_date: {
                                                title: "Registration Date"
                                              }
                                            },
                                            "csv-title": "Vendor_Export",
                                            "json-data":
                                              _vm.$refs.vendorTable
                                                .allFilteredData
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Download Table CSV/Excel\n                  "
                                          ),
                                          _c("i", {
                                            staticClass: "fa fa-download"
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group ml-2",
                                      staticStyle: { float: "right" }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.importVendors",
                                              modifiers: { importVendors: true }
                                            }
                                          ],
                                          staticClass:
                                            "btn float-right mr-2 btn-success",
                                          attrs: {
                                            title:
                                              "Import all vendors and there associated services from existing project to this project " +
                                              _vm.$session.get("projectname"),
                                            type: "button"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Import vendors from existing project "
                                          ),
                                          _c("i", { staticClass: "fa fa-plus" })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-client-table", {
                            ref: "vendorTable",
                            staticClass: "statusTable",
                            attrs: {
                              columns: _vm.columnsVendorServices,
                              data: _vm.wtsVendorList,
                              options: _vm.optionsVendorServices,
                              theme: _vm.theme,
                              id: "dataTable"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "status",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-success w-100"
                                      },
                                      [_vm._v("Active")]
                                    )
                                  ])
                                }
                              },
                              {
                                key: "fname",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(props.row.fname) +
                                        " " +
                                        _vm._s(props.row.lname) +
                                        "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "company_name",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.getVendorCompanyName(
                                            props.row.vid
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "creation_date",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("moment")(
                                          props.row.creation_date,
                                          "MM-DD-YYYY hh:mm A"
                                        )
                                      ) + "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "services",
                                fn: function(props) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-sm",
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadVendorServices(
                                                props.row.vid
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", { staticClass: "fa fa-eye" }),
                                          _vm._v(" View Assigned Services")
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }
                              },
                              {
                                key: "edit",
                                fn: function(props) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.editVendorEmail",
                                              modifiers: {
                                                editVendorEmail: true
                                              }
                                            }
                                          ],
                                          staticClass: "btn-sm",
                                          attrs: { variant: "success" },
                                          on: {
                                            click: function($event) {
                                              return _vm.sendVendorId(
                                                props.row.vid,
                                                props.row.email
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v("Edit Email "),
                                          _c("i", { staticClass: "fa fa-edit" })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }
                              },
                              {
                                key: "delete",
                                fn: function(props) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-sm",
                                          attrs: { variant: "danger" },
                                          on: {
                                            click: function($event) {
                                              return _vm.DeleteVendor(
                                                props.row.vid,
                                                props.row.email
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }
                              }
                            ])
                          }),
                          _vm.serviceLength > 0
                            ? _c(
                                "b-progress",
                                {
                                  staticClass: "mb-3",
                                  staticStyle: {
                                    "font-size": "0.8rem",
                                    width: "300px",
                                    float: "right"
                                  },
                                  attrs: {
                                    max: _vm.serviceLength,
                                    height: "1.5rem",
                                    "show-value": "",
                                    animated: ""
                                  }
                                },
                                [
                                  _c("b-progress-bar", {
                                    attrs: {
                                      value: _vm.DeletedService,
                                      label:
                                        "Progress: " +
                                        _vm.DeletedService +
                                        "/" +
                                        _vm.serviceLength,
                                      variant: "success"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("br"),
                          _c(
                            "b-modal",
                            {
                              ref: "editVendorEmail",
                              attrs: {
                                id: "editVendorEmail",
                                title: "Edit Vendor Email",
                                "title-tag": "h2",
                                centered: "",
                                size: "lg",
                                "hide-footer": ""
                              }
                            },
                            [
                              _c(
                                "form",
                                {
                                  staticClass: "pb-2",
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.emailvalidateBeforeSubmit(
                                        "editemail"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("p", { staticClass: "text-muted" }, [
                                    _vm._v("Enter new email.")
                                  ]),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-input-group",
                                            { staticClass: "mb-2" },
                                            [
                                              _c(
                                                "b-input-group-prepend",
                                                [
                                                  _c("b-input-group-text", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-envelope"
                                                    })
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.email,
                                                    expression: "email"
                                                  }
                                                ],
                                                ref: "email",
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "email",
                                                  placeholder: "Email Address",
                                                  name: "email"
                                                },
                                                domProps: { value: _vm.email },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.email =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.emailerror,
                                        expression: "emailerror"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.emailerror,
                                          expression: "emailerror"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("Error. Please try again.")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-4",
                                      attrs: {
                                        variant: "success",
                                        type: "submit",
                                        block: ""
                                      }
                                    },
                                    [_vm._v("Edit Email")]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c("WtsImport", {
                            attrs: {
                              id: "scrollToServices",
                              update: _vm.update,
                              name: _vm.vcnProp,
                              vid: _vm.vid
                            }
                          })
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { staticClass: "fa fa-envelope" }),
                            _vm._v(" Email Invites\n            ")
                          ]),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("h4", { staticClass: "d-inline" }, [
                                    _vm._v(
                                      "Vendor Or Well Site Supervisor Email Invite List"
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group ml-2",
                                      staticStyle: {
                                        width: "200px",
                                        float: "right"
                                      }
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.filter_emails,
                                              expression: "filter_emails"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          staticStyle: {
                                            border: "2px solid #c1c1c1"
                                          },
                                          attrs: { id: "emailFilter" },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.filter_emails = $event
                                                  .target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              _vm.filterEmails
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: {
                                                selected: "",
                                                value: "2"
                                              }
                                            },
                                            [_vm._v("All Emails")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "1" } },
                                            [_vm._v("Registered Emails")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "0" } },
                                            [_vm._v("Unregistered Emails")]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm.$refs.inviteTable
                                    ? _c(
                                        "vue-json-to-csv",
                                        {
                                          staticClass:
                                            "btn float-right ml-3 btn-md btn-blue",
                                          attrs: {
                                            labels: {
                                              mail: { title: "Email" },
                                              creation_date: {
                                                title: "Invite Date"
                                              }
                                            },
                                            "csv-title": "Invite_Export",
                                            "json-data":
                                              _vm.$refs.inviteTable
                                                .allFilteredData
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Download Table CSV/Excel\n                  "
                                          ),
                                          _c("i", {
                                            staticClass: "fa fa-download"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-client-table", {
                            ref: "inviteTable",
                            staticClass: "statusTable",
                            attrs: {
                              columns: _vm.emailListColumns,
                              data: _vm.filter_emails_data,
                              options: _vm.emailListOptions,
                              theme: _vm.theme,
                              id: "dataTable"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "status",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      props.row.status == 1
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-success w-100"
                                            },
                                            [_vm._v("Registered")]
                                          )
                                        : _vm._e(),
                                      props.row.status == 0
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-danger w-100"
                                            },
                                            [_vm._v("Unregistered")]
                                          )
                                        : _vm._e()
                                    ])
                                  }
                                },
                                {
                                  key: "creation_date",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("moment")(
                                            props.row.creation_date,
                                            "MM-DD-YYYY hh:mm A"
                                          )
                                        ) + "\n              "
                                      )
                                    ])
                                  }
                                },
                                {
                                  key: "delete_email",
                                  fn: function(props) {
                                    return props.row.status == 0
                                      ? _c(
                                          "div",
                                          {},
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "btn-sm",
                                                attrs: { variant: "danger" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteEmail(
                                                      props.row
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-trash"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { staticClass: "fa fa-cog" }),
                            !_vm.$route.meta.is_resource
                              ? _c("span", [_vm._v(" AFE'S")])
                              : _c("span", [
                                  _vm._v(" Cost\n                Center")
                                ])
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("h4", { staticClass: "d-inline" }, [
                                    !_vm.$route.meta.is_resource
                                      ? _c("span", [_vm._v(" AFE'S")])
                                      : _c("span", [
                                          _vm._v(
                                            " Cost\n                    Center"
                                          )
                                        ])
                                  ]),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.addAfeNum",
                                          modifiers: { addAfeNum: true }
                                        }
                                      ],
                                      staticClass: "float-right",
                                      attrs: { variant: "success" },
                                      on: {
                                        click: function($event) {
                                          ;(_vm.id = 0), (_vm.error = false)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v("\n                  Add "),
                                      !_vm.$route.meta.is_resource
                                        ? _c("span", [_vm._v(" AFE")])
                                        : _c("span", [_vm._v("Cost Center")]),
                                      _c("i", { staticClass: "fa fa-plus" })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-client-table",
                            {
                              staticClass: "statusTable",
                              attrs: {
                                stacked: "",
                                columns: _vm.columnsAfe,
                                data: _vm.wtsAfeData,
                                options: _vm.optionsAfe,
                                theme: _vm.theme,
                                id: "dataTable"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "status",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      props.row.status === 0
                                        ? _c("span", [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-success w-100"
                                              },
                                              [_vm._v("Active")]
                                            )
                                          ])
                                        : _vm._e(),
                                      props.row.status !== 0
                                        ? _c("span", [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-danger w-100"
                                              },
                                              [_vm._v("Inactive")]
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  }
                                },
                                {
                                  key: "uwi",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            !props.row.uwi_2
                                              ? props.row.uwi
                                              : !props.row.uwi_3
                                              ? props.row.uwi_2
                                              : props.row.uwi_3
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  }
                                },
                                {
                                  key: "cw_start_date",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      props.row.cw_start_date !== "3000-01-01"
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  props.row.cw_start_date
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  }
                                },
                                {
                                  key: "cw_end_date",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      props.row.cw_end_date !== "3000-01-01"
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(props.row.cw_end_date) +
                                                "\n                "
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  }
                                },
                                {
                                  key: "cc",
                                  fn: function(props) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "btn-sm mr-3",
                                            attrs: { variant: "success" },
                                            on: {
                                              click: function($event) {
                                                return _vm.enterCC(
                                                  props.row.pwcaid,
                                                  props.row.afenum,
                                                  props.row.category,
                                                  props.row.companyid
                                                )
                                              }
                                            }
                                          },
                                          [
                                            !_vm.$route.meta.is_resource
                                              ? _c("span", [
                                                  _vm._v(" Enter Cost Codes ")
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    " Enter\n                    Major/Minor/Descriptions "
                                                  )
                                                ]),
                                            _c("i", {
                                              staticClass: "fa fa-pencil"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }
                                },
                                {
                                  key: "edit",
                                  fn: function(props) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName: "v-b-modal.editAfeNum",
                                                modifiers: { editAfeNum: true }
                                              }
                                            ],
                                            staticClass: "btn-sm",
                                            attrs: { variant: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.sendEditInfo(
                                                  props.row.pwcaid,
                                                  props.row.pwid,
                                                  props.row.pcid,
                                                  props.row.afenum,
                                                  props.row.cw_start_date,
                                                  props.row.cw_end_date,
                                                  props.row.status,
                                                  props.row.is_default
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-edit"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }
                                },
                                {
                                  key: "TotalBudget",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      _vm._v(
                                        "\n\n                " +
                                          _vm._s(
                                            _vm.formatPrice(props.row.budget)
                                          ) +
                                          "\n\n              "
                                      )
                                    ])
                                  }
                                },
                                {
                                  key: "TotalCost_vendor",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.formatPrice(
                                              props.row.vendorTotal
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  }
                                },
                                {
                                  key: "totalNet",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.formatPrice(
                                              props.row.budget -
                                                props.row.vendorTotal
                                            )
                                          ) +
                                          "\n\n              "
                                      )
                                    ])
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { float: "right" },
                                  attrs: { slot: "afterFilter" },
                                  slot: "afterFilter"
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      staticStyle: { "margin-left": "50px" },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadAfeData()
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fa fa-sync-alt" })]
                                  )
                                ]
                              ),
                              _c("template", { slot: "h__afenum" }, [
                                !_vm.$route.meta.is_resource
                                  ? _c("span", [_vm._v("AFE Number")])
                                  : _c("span", [_vm._v("Cost Center")])
                              ]),
                              _c("template", { slot: "h__uwi" }, [
                                !_vm.$route.meta.is_resource
                                  ? _c("span", [_vm._v("UWI Number")])
                                  : _c("span", [_vm._v("Operation Number")])
                              ]),
                              _c("template", { slot: "h__cc" }, [
                                !_vm.$route.meta.is_resource
                                  ? _c("span", [_vm._v("Enter Cost Codes")])
                                  : _c("span", [
                                      _vm._v("Major/Minor/Descriptions")
                                    ])
                              ])
                            ],
                            2
                          ),
                          _c(
                            "b-modal",
                            {
                              ref: "addNewCC",
                              attrs: {
                                id: "addNewCC",
                                title: "Add New Cost Code",
                                "title-tag": "h2",
                                centered: "",
                                size: "lg",
                                "hide-footer": ""
                              }
                            },
                            [
                              _c("b-row", [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12 form-group" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.addNewCostName,
                                          expression: "addNewCostName"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.addNewCostName },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.addNewCostName =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "success",
                                          type: "submit",
                                          block: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.addNewCostCode()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v("Add "),
                                        _c("i", { staticClass: "fa fa-plus" })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          ),
                          _vm.ccPanel == 1 && _vm.afenum
                            ? _c(
                                "div",
                                { attrs: { id: "afepanel" } },
                                [
                                  _c("h4", {}, [
                                    !_vm.$route.meta.is_resource
                                      ? _c("span", [_vm._v(" AFE ")])
                                      : _c("span", [_vm._v(" Operation ")]),
                                    _vm._v(
                                      "Number:\n                " +
                                        _vm._s(_vm.afenum) +
                                        " (" +
                                        _vm._s(_vm.category) +
                                        ")"
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "clearfix" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.importChainFromAnotherProjectModal",
                                              modifiers: {
                                                importChainFromAnotherProjectModal: true
                                              }
                                            }
                                          ],
                                          staticClass: "btn-sm pull-right mr-2",
                                          attrs: { variant: "success" }
                                        },
                                        [
                                          _vm._v(" Import "),
                                          !_vm.$route.meta.is_resource
                                            ? _c("span", [
                                                _vm._v(
                                                  " Cost\n                    Codes"
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  " Major/Minor/Descriptions"
                                                )
                                              ]),
                                          _vm._v(" From Another Project "),
                                          _c("i", { staticClass: "fa fa-plus" })
                                        ]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.exportChainToAnotherProjectModal",
                                              modifiers: {
                                                exportChainToAnotherProjectModal: true
                                              }
                                            }
                                          ],
                                          staticClass: "btn-sm pull-right mr-2",
                                          attrs: { variant: "success" }
                                        },
                                        [
                                          _vm._v("\n                  Export "),
                                          !_vm.$route.meta.is_resource
                                            ? _c("span", [
                                                _vm._v(" Cost Codes")
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  "\n                    Major/Minor/Descriptions"
                                                )
                                              ]),
                                          _vm._v(" To Another Project "),
                                          _c("i", { staticClass: "fa fa-plus" })
                                        ]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal.importBudget",
                                              modifiers: { importBudget: true }
                                            }
                                          ],
                                          staticClass: "btn-sm pull-right mr-2",
                                          attrs: { variant: "success" }
                                        },
                                        [
                                          _vm._v(
                                            " Import Budget For\n                  Cost Codes "
                                          ),
                                          _c("i", { staticClass: "fa fa-plus" })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("hr", { staticClass: "clearfix mt-4" }),
                                  _c(
                                    "b-modal",
                                    {
                                      ref: "importBudget",
                                      attrs: {
                                        id: "importBudget",
                                        title: "Import Budget For Cost Codes",
                                        "title-tag": "h2",
                                        centered: "",
                                        size: "lg",
                                        "hide-footer": ""
                                      },
                                      on: {
                                        shown: function($event) {
                                          return _vm.clearHistory()
                                        }
                                      }
                                    },
                                    [
                                      _c("b-row", [
                                        _c("div", { staticClass: "col-md-6" }, [
                                          _c("h5", [_vm._v("CSV Files Only")]),
                                          _c("input", {
                                            ref: "csvFile",
                                            staticClass: "mt-2",
                                            attrs: {
                                              id: "fileInput",
                                              type: "file"
                                            },
                                            on: { change: _vm.uploadBudget }
                                          })
                                        ]),
                                        _vm.doc.length > 0 && _vm.toggleBudget
                                          ? _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c("h5", [_vm._v("Import")]),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary",
                                                    on: {
                                                      "~click": function(
                                                        $event
                                                      ) {
                                                        return _vm.importBudget(
                                                          _vm.doc
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Import")]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.togglLoading
                                          ? _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c("h5", [
                                                  _vm._v(
                                                    "Loading cost codes..."
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.toggleBudget
                                          ? _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c("h5", [_vm._v("Template")]),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary",
                                                    attrs: {
                                                      href:
                                                        "/templates/CostCodeBudget.csv"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Download Template "
                                                    ),
                                                    _c("i", {
                                                      staticClass: "fa fa-file"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "b-row",
                                        {
                                          staticStyle: {
                                            display: "block !important"
                                          }
                                        },
                                        [
                                          _vm.doc.length > 0 &&
                                          _vm.toggleBudget &&
                                          "updatedbudget"
                                            ? _c(
                                                "h5",
                                                {
                                                  staticStyle: {
                                                    margin: "15px 0px 10px 10px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    Matched Cost Code Chains:"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(_vm.allCostCodes, function(
                                            val,
                                            i
                                          ) {
                                            return _vm.doc.length > 0 &&
                                              _vm.toggleBudget &&
                                              "updatedbudget" in val
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "0px 0px 5px 10px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "b",
                                                      {
                                                        staticStyle: {
                                                          margin: "5px",
                                                          color: "black"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "b",
                                                          {
                                                            staticStyle: {
                                                              color: "black"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(i + 1) +
                                                                ". "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(val.code) +
                                                            "--" +
                                                            _vm._s(
                                                              val.cctwo_code
                                                            ) +
                                                            "--" +
                                                            _vm._s(
                                                              val.ccthree_code
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    val.updatedbudget == null
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fa fa-check",
                                                          staticStyle: {
                                                            color:
                                                              "lightseagreen"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ]
                                                )
                                              : _vm._e()
                                          }),
                                          _c("hr"),
                                          _vm.unmatchedData.length > 0
                                            ? _c(
                                                "h5",
                                                {
                                                  staticStyle: {
                                                    margin: "15px 0px 10px 10px"
                                                  }
                                                },
                                                [_vm._v("Unmatched Cost Codes")]
                                              )
                                            : _vm._e(),
                                          _vm._l(_vm.unmatchedData, function(
                                            val,
                                            i
                                          ) {
                                            return _vm.unmatchedData.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin:
                                                        "0px 0px 5px 10px",
                                                      color: "red"
                                                    }
                                                  },
                                                  [
                                                    val.CC1 != ""
                                                      ? _c(
                                                          "b",
                                                          {
                                                            staticStyle: {
                                                              margin: "5px",
                                                              color: "brown"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "b",
                                                              {
                                                                staticStyle: {
                                                                  color: "black"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    i + 1
                                                                  ) +
                                                                    ".\n                      "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              _vm._s(val.CC1) +
                                                                "--" +
                                                                _vm._s(
                                                                  val.CC2
                                                                ) +
                                                                "--" +
                                                                _vm._s(
                                                                  val.Description
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              : _vm._e()
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-modal",
                                    {
                                      ref: "addBudgetModal",
                                      attrs: {
                                        id: "addBudgetModal",
                                        title: "Add Budget For Cost Code",
                                        "title-tag": "h2",
                                        centered: "",
                                        size: "lg",
                                        "hide-footer": ""
                                      }
                                    },
                                    [
                                      _c("b-row", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-12 form-group"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.budget,
                                                  expression: "budget"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: { type: "text" },
                                              domProps: { value: _vm.budget },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.budget =
                                                    $event.target.value
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-12" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "success",
                                                  type: "submit",
                                                  block: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addBudgetToCostCode()
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v("Assign Budget "),
                                                _c("i", {
                                                  staticClass: "fa fa-plus"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-modal",
                                    {
                                      ref: "editBudgetModal",
                                      attrs: {
                                        id: "editBudgetModal",
                                        title: "Edit Budget For Cost Code",
                                        "title-tag": "h2",
                                        centered: "",
                                        size: "lg",
                                        "hide-footer": ""
                                      }
                                    },
                                    [
                                      _c("b-row", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-12 form-group"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.budget,
                                                  expression: "budget"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: { type: "text" },
                                              domProps: { value: _vm.budget },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.budget =
                                                    $event.target.value
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-12" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "success",
                                                  type: "submit",
                                                  block: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editBudgetToCostCode()
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v("Edit Budget "),
                                                _c("i", {
                                                  staticClass: "fa fa-plus"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "position-relative" },
                                    [
                                      _c("rotate-square2", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.deletingAllAssignments,
                                            expression: "deletingAllAssignments"
                                          }
                                        ],
                                        staticStyle: {
                                          position: "fixed !important",
                                          bottom: "30% !important",
                                          top: "inherit !important"
                                        },
                                        attrs: { id: "loading-gif" }
                                      }),
                                      _c("h2", [_vm._v("Cost Code Chain")]),
                                      _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            id: "modal-3",
                                            size: "xl lg",
                                            title: "Company list and services",
                                            "ok-only": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-client-table",
                                            {
                                              ref: "ccVendorTable",
                                              staticClass:
                                                "rt-table-striped vendor-table statusTable ServiceDetailsListTable forth-level",
                                              staticStyle: {
                                                "margin-left": "15px"
                                              },
                                              attrs: {
                                                data: _vm.vendorCompanyNameAFE,
                                                columns:
                                                  _vm.columnsCCVendorAssignedServices,
                                                options:
                                                  _vm.optionsCCVendorAssignedServices,
                                                theme: _vm.theme
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "vid",
                                                    fn: function(props) {
                                                      return _c("div", {}, [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-success btn-sm mr-2",
                                                            attrs: {
                                                              id:
                                                                "Toggle-services"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.provideVendorServices(
                                                                  props.row
                                                                    .projectid,
                                                                  props.row
                                                                    .companyid,
                                                                  props.row
                                                                    .pwcaid,
                                                                  props.row
                                                                    .cconeid,
                                                                  props.row
                                                                    .cctwoid,
                                                                  props.row
                                                                    .ccthreeid,
                                                                  props.row.vid
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass: "fa",
                                                              class: {
                                                                "fa-chevron-down":
                                                                  _vm.childRowVendor ==
                                                                  props.row.vid,
                                                                "fa-chevron-right":
                                                                  _vm.childRowVendor !=
                                                                  props.row.vid
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.provideVendorServices(
                                                                  props.row
                                                                    .projectid,
                                                                  props.row
                                                                    .companyid,
                                                                  props.row
                                                                    .cconeid,
                                                                  props.row
                                                                    .cctwoid,
                                                                  props.row
                                                                    .ccthreeid,
                                                                  props.row.vid
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                props.row
                                                                  .company_name
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "30px",
                                                              float: "right",
                                                              display: "flex"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "30px"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Total Service Estimate: "
                                                                ),
                                                                _c("b", [
                                                                  _vm._v(
                                                                    " $" +
                                                                      _vm._s(
                                                                        props
                                                                          .row
                                                                          .totalestimates
                                                                          ? _vm.formatPrice(
                                                                              props
                                                                                .row
                                                                                .totalestimates
                                                                            )
                                                                          : _vm.formatPrice(
                                                                              0
                                                                            )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "30px"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Total Cost: "
                                                                ),
                                                                _c("b", [
                                                                  _vm._v(
                                                                    " $" +
                                                                      _vm._s(
                                                                        _vm.formatPrice(
                                                                          props
                                                                            .row
                                                                            .vendorTotal
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Difference:"
                                                                )
                                                              ]),
                                                              (props.row
                                                                .totalestimates
                                                                ? props.row
                                                                    .totalestimates
                                                                : 0) -
                                                                props.row
                                                                  .vendorTotal <
                                                              0
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        "margin-right":
                                                                          "0px",
                                                                        color:
                                                                          "red",
                                                                        "font-weight":
                                                                          "bold"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c("b", [
                                                                        _vm._v(
                                                                          "\n                              $" +
                                                                            _vm._s(
                                                                              _vm.formatPrice(
                                                                                (props
                                                                                  .row
                                                                                  .totalestimates
                                                                                  ? props
                                                                                      .row
                                                                                      .totalestimates
                                                                                  : 0) -
                                                                                  props
                                                                                    .row
                                                                                    .vendorTotal
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ])
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        "margin-right":
                                                                          "0px",
                                                                        color:
                                                                          "green",
                                                                        "font-weight":
                                                                          "bold"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c("b", [
                                                                        _vm._v(
                                                                          "\n                              $" +
                                                                            _vm._s(
                                                                              _vm.formatPrice(
                                                                                (props
                                                                                  .row
                                                                                  .totalestimates
                                                                                  ? props
                                                                                      .row
                                                                                      .totalestimates
                                                                                  : 0) -
                                                                                  props
                                                                                    .row
                                                                                    .vendorTotal
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ])
                                                                    ]
                                                                  )
                                                            ])
                                                          ]
                                                        )
                                                      ])
                                                    }
                                                  },
                                                  {
                                                    key: "child_row",
                                                    fn: function(props) {
                                                      return [
                                                        _c(
                                                          "b-modal",
                                                          {
                                                            attrs: {
                                                              id:
                                                                "delete-all-service",
                                                              title:
                                                                "Delete all services"
                                                            },
                                                            on: {
                                                              ok: function(
                                                                $event
                                                              ) {
                                                                return _vm.deleteAllVendorService(
                                                                  _vm
                                                                    .afeDetailsTableDataRaj[0]
                                                                    .companyid,
                                                                  _vm
                                                                    .afeDetailsTableDataRaj[0]
                                                                    .projectid,
                                                                  _vm
                                                                    .afeDetailsTableDataRaj[0]
                                                                    .pwcaid,
                                                                  _vm
                                                                    .afeDetailsTableDataRaj[0]
                                                                    .cconeid,
                                                                  _vm
                                                                    .afeDetailsTableDataRaj[0]
                                                                    .cctwoid,
                                                                  _vm
                                                                    .afeDetailsTableDataRaj[0]
                                                                    .ccthreeid,
                                                                  _vm
                                                                    .afeDetailsTableDataRaj[0]
                                                                    .vid
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "my-4"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Are you sure you want to delete all services ?"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "b-modal",
                                                                rawName:
                                                                  "v-b-modal.delete-all-service",
                                                                modifiers: {
                                                                  "delete-all-service": true
                                                                }
                                                              }
                                                            ],
                                                            staticClass:
                                                              "btn-danger btn-sm",
                                                            staticStyle: {
                                                              margin:
                                                                "5px 0 5px 44px"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-trash"
                                                            }),
                                                            _vm._v(
                                                              " Delete All Services"
                                                            )
                                                          ]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            "   Total No. Services: "
                                                          ),
                                                          _c("b", [
                                                            _vm._v(
                                                              "(" +
                                                                _vm._s(
                                                                  _vm.vendorCompanyServiceSize
                                                                ) +
                                                                ")"
                                                            )
                                                          ])
                                                        ]),
                                                        _c("v-client-table", {
                                                          ref: "vendorTable",
                                                          staticStyle: {
                                                            "margin-left":
                                                              "40px"
                                                          },
                                                          attrs: {
                                                            data:
                                                              _vm.afeDetailsTableDataRaj,
                                                            columns:
                                                              _vm.columnsCCServiceAssignedServices,
                                                            options:
                                                              _vm.optionsCCServiceAssignedServices,
                                                            theme: _vm.theme
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "delete",
                                                                fn: function(
                                                                  props
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {},
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn-danger btn-sm",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteVendorService(
                                                                                props
                                                                                  .row
                                                                                  .companyid,
                                                                                props
                                                                                  .row
                                                                                  .projectid,
                                                                                props
                                                                                  .row
                                                                                  .pwcaid,
                                                                                props
                                                                                  .row
                                                                                  .cconeid,
                                                                                props
                                                                                  .row
                                                                                  .cctwoid,
                                                                                props
                                                                                  .row
                                                                                  .ccthreeid,
                                                                                props
                                                                                  .row
                                                                                  .vid,
                                                                                props
                                                                                  .row
                                                                                  .serviceid,
                                                                                props
                                                                                  .row
                                                                                  .uom1total,
                                                                                props
                                                                                  .row
                                                                                  .uom2total,
                                                                                props
                                                                                  .row
                                                                                  .uom3total,
                                                                                props
                                                                                  .row
                                                                                  .service_ccid
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-trash"
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              },
                                                              {
                                                                key: "icon",
                                                                fn: function(
                                                                  props
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {},
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn-danger btn-sm",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteVendorService(
                                                                                props
                                                                                  .row
                                                                                  .companyid,
                                                                                props
                                                                                  .row
                                                                                  .projectid,
                                                                                props
                                                                                  .row
                                                                                  .pwcaid,
                                                                                props
                                                                                  .row
                                                                                  .cconeid,
                                                                                props
                                                                                  .row
                                                                                  .cctwoid,
                                                                                props
                                                                                  .row
                                                                                  .ccthreeid,
                                                                                props
                                                                                  .row
                                                                                  .vid,
                                                                                props
                                                                                  .row
                                                                                  .serviceid,
                                                                                props
                                                                                  .row
                                                                                  .uom1total,
                                                                                props
                                                                                  .row
                                                                                  .uom2total,
                                                                                props
                                                                                  .row
                                                                                  .uom3total,
                                                                                props
                                                                                  .row
                                                                                  .service_ccid
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-trash"
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              },
                                                              {
                                                                key:
                                                                  "servicename",
                                                                fn: function(
                                                                  props
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {},
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            props.index
                                                                          ) +
                                                                          ". " +
                                                                          _vm._s(
                                                                            props
                                                                              .row
                                                                              .servicename
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              },
                                                              {
                                                                key:
                                                                  "servicecost",
                                                                fn: function(
                                                                  props
                                                                ) {
                                                                  return props
                                                                    .row
                                                                    .isflexible ==
                                                                    "N"
                                                                    ? _c(
                                                                        "div",
                                                                        {},
                                                                        [
                                                                          _vm._v(
                                                                            "\n                          $" +
                                                                              _vm._s(
                                                                                _vm.formatPrice(
                                                                                  props
                                                                                    .row
                                                                                    .uom1total +
                                                                                    props
                                                                                      .row
                                                                                      .uom2total +
                                                                                    props
                                                                                      .row
                                                                                      .uom3total
                                                                                )
                                                                              ) +
                                                                              "\n                        "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        {},
                                                                        [
                                                                          _vm._v(
                                                                            "\n                          $" +
                                                                              _vm._s(
                                                                                props
                                                                                  .row
                                                                                  .pu
                                                                                  ? _vm.formatPrice(
                                                                                      props
                                                                                        .row
                                                                                        .pu
                                                                                    )
                                                                                  : _vm.formatPrice(
                                                                                      0
                                                                                    )
                                                                              ) +
                                                                              "\n                        "
                                                                          )
                                                                        ]
                                                                      )
                                                                }
                                                              },
                                                              {
                                                                key:
                                                                  "servicebudget",
                                                                fn: function(
                                                                  props
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {},
                                                                    [
                                                                      _vm._v(
                                                                        "\n\n                          " +
                                                                          _vm._s(
                                                                            props
                                                                              .row
                                                                              .pu1
                                                                              ? _vm.formatPrice(
                                                                                  props
                                                                                    .row
                                                                                    .qty1 *
                                                                                    props
                                                                                      .row
                                                                                      .pu1 +
                                                                                    props
                                                                                      .row
                                                                                      .qty2 *
                                                                                      props
                                                                                        .row
                                                                                        .pu2 +
                                                                                    props
                                                                                      .row
                                                                                      .qty3 *
                                                                                      props
                                                                                        .row
                                                                                        .pu3
                                                                                )
                                                                              : _vm.formatPrice(
                                                                                  0
                                                                                )
                                                                          ) +
                                                                          "\n\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              },
                                                              {
                                                                key:
                                                                  "servicenet",
                                                                fn: function(
                                                                  props
                                                                ) {
                                                                  return props
                                                                    .row
                                                                    .isflexible ==
                                                                    "N"
                                                                    ? _c(
                                                                        "div",
                                                                        {},
                                                                        [
                                                                          (
                                                                            props
                                                                              .row
                                                                              .qty1 *
                                                                              props
                                                                                .row
                                                                                .pu1 +
                                                                            props
                                                                              .row
                                                                              .qty2 *
                                                                              props
                                                                                .row
                                                                                .pu2 +
                                                                            props
                                                                              .row
                                                                              .qty3 *
                                                                              props
                                                                                .row
                                                                                .pu3
                                                                          ).toFixed(
                                                                            2
                                                                          ) -
                                                                            (
                                                                              props
                                                                                .row
                                                                                .uom1total +
                                                                              props
                                                                                .row
                                                                                .uom2total +
                                                                              props
                                                                                .row
                                                                                .uom3total
                                                                            ).toFixed(
                                                                              2
                                                                            ) <
                                                                          0
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle: {
                                                                                    color:
                                                                                      "red",
                                                                                    "font-weight":
                                                                                      "bold"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        props
                                                                                          .row
                                                                                          .pu1
                                                                                          ? _vm.formatPrice(
                                                                                              props
                                                                                                .row
                                                                                                .qty1 *
                                                                                                props
                                                                                                  .row
                                                                                                  .pu1 +
                                                                                                props
                                                                                                  .row
                                                                                                  .qty2 *
                                                                                                  props
                                                                                                    .row
                                                                                                    .pu2 +
                                                                                                props
                                                                                                  .row
                                                                                                  .qty3 *
                                                                                                  props
                                                                                                    .row
                                                                                                    .pu3 -
                                                                                                (props
                                                                                                  .row
                                                                                                  .uom1total +
                                                                                                  props
                                                                                                    .row
                                                                                                    .uom2total +
                                                                                                  props
                                                                                                    .row
                                                                                                    .uom3total)
                                                                                            )
                                                                                          : _vm.formatPrice(
                                                                                              0
                                                                                            )
                                                                                      ) +
                                                                                      "\n                          "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle: {
                                                                                    color:
                                                                                      "green",
                                                                                    "font-weight":
                                                                                      "bold"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        props
                                                                                          .row
                                                                                          .pu1
                                                                                          ? _vm.formatPrice(
                                                                                              props
                                                                                                .row
                                                                                                .qty1 *
                                                                                                props
                                                                                                  .row
                                                                                                  .pu1 +
                                                                                                props
                                                                                                  .row
                                                                                                  .qty2 *
                                                                                                  props
                                                                                                    .row
                                                                                                    .pu2 +
                                                                                                props
                                                                                                  .row
                                                                                                  .qty3 *
                                                                                                  props
                                                                                                    .row
                                                                                                    .pu3 -
                                                                                                (props
                                                                                                  .row
                                                                                                  .uom1total +
                                                                                                  props
                                                                                                    .row
                                                                                                    .uom2total +
                                                                                                  props
                                                                                                    .row
                                                                                                    .uom3total)
                                                                                            )
                                                                                          : _vm.formatPrice(
                                                                                              0
                                                                                            )
                                                                                      ) +
                                                                                      "\n                          "
                                                                                  )
                                                                                ]
                                                                              )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        {},
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle: {
                                                                                color:
                                                                                  "black",
                                                                                "font-weight":
                                                                                  "bold",
                                                                                "background-color":
                                                                                  "cadetblue !important"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            " +
                                                                                  _vm._s(
                                                                                    props
                                                                                      .row
                                                                                      .pu
                                                                                      ? _vm.formatPrice(
                                                                                          props
                                                                                            .row
                                                                                            .pu
                                                                                        )
                                                                                      : _vm.formatPrice(
                                                                                          0
                                                                                        )
                                                                                  ) +
                                                                                  "\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                880785208
                                              )
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content":
                                                      "space-between",
                                                    "margin-bottom": "20px"
                                                  },
                                                  attrs: {
                                                    slot: "prependHead"
                                                  },
                                                  slot: "prependHead"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "13px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "20px"
                                                          }
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              "cost code 1:"
                                                            )
                                                          ]),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.cc1_code
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "20px"
                                                          }
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              "cost code 2:"
                                                            )
                                                          ]),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.cc2_code
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "20px"
                                                          }
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              "cost code 3:"
                                                            )
                                                          ]),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.cc3_code
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-right": "20px"
                                                        }
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Total Service Estimate:"
                                                          )
                                                        ]),
                                                        _vm._v(
                                                          " $" +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                _vm.totalServiceEstimate
                                                              )
                                                            ) +
                                                            "\n                        "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-right": "20px"
                                                        }
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Total Actual:"
                                                          )
                                                        ]),
                                                        _vm._v(
                                                          " $" +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                _vm.TotalActual
                                                              )
                                                            ) +
                                                            "\n                        "
                                                        )
                                                      ]
                                                    ),
                                                    _c("span", [
                                                      _c("b", [
                                                        _vm._v("Difference:")
                                                      ]),
                                                      _vm.totalServiceEstimate -
                                                        _vm.TotalActual <
                                                      0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "20px",
                                                                color: "red",
                                                                "font-weight":
                                                                  "bold"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            $" +
                                                                  _vm._s(
                                                                    _vm.formatPrice(
                                                                      _vm.totalServiceEstimate -
                                                                        _vm.TotalActual
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "20px",
                                                                color: "green",
                                                                "font-weight":
                                                                  "bold"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            $" +
                                                                  _vm._s(
                                                                    _vm.formatPrice(
                                                                      _vm.totalServiceEstimate -
                                                                        _vm.TotalActual
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          )
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "cost-codes" },
                                        [
                                          _c(
                                            "v-client-table",
                                            {
                                              ref: "CC3Tables",
                                              staticClass: "statusTable",
                                              attrs: {
                                                stacked: "",
                                                columns:
                                                  _vm.columnsCC1AssignedServices,
                                                data: _vm.allCostCodes,
                                                options:
                                                  _vm.optionsCC1AssignedServices,
                                                theme: _vm.theme
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "code",
                                                    fn: function(props) {
                                                      return _c("div", {}, [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              props.row.code
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ])
                                                    }
                                                  },
                                                  {
                                                    key: "cctwo_code",
                                                    fn: function(props) {
                                                      return _c("div", {}, [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              props.row
                                                                .cctwo_code
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ])
                                                    }
                                                  },
                                                  {
                                                    key: "ccthree_code",
                                                    fn: function(props) {
                                                      return _c("div", {}, [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              props.row
                                                                .ccthree_code
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ])
                                                    }
                                                  },
                                                  {
                                                    key: "vendor_service",
                                                    fn: function(props) {
                                                      return _c("div", {}, [
                                                        props.row.ccthree_code.includes(
                                                          "---"
                                                        )
                                                          ? _c("div", [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn-success btn-sm mr-2",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.provideVendorCompanyName(
                                                                        props
                                                                          .row
                                                                          .projectid,
                                                                        props
                                                                          .row
                                                                          .companyid,
                                                                        props
                                                                          .row
                                                                          .pwcaid,
                                                                        props
                                                                          .row
                                                                          .cconeid,
                                                                        props
                                                                          .row
                                                                          .cctwoid,
                                                                        props
                                                                          .row
                                                                          .ccthreeid,
                                                                        props
                                                                          .row
                                                                          .code,
                                                                        props
                                                                          .row
                                                                          .cctwo_code,
                                                                        props
                                                                          .row
                                                                          .ccthree_code
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa",
                                                                    class: {
                                                                      "fa-chevron-down":
                                                                        _vm.childRowCC3 ==
                                                                        props
                                                                          .row
                                                                          .ccthreeid,
                                                                      "fa-chevron-right":
                                                                        _vm.childRowCC3 !=
                                                                        props
                                                                          .row
                                                                          .ccthreeid
                                                                    }
                                                                  })
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-style":
                                                                      "italic",
                                                                    color:
                                                                      "#f86c6b"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.provideVendorCompanyName(
                                                                        props
                                                                          .row
                                                                          .projectid,
                                                                        props
                                                                          .row
                                                                          .companyid,
                                                                        props
                                                                          .row
                                                                          .pwcaid,
                                                                        props
                                                                          .row
                                                                          .cconeid,
                                                                        props
                                                                          .row
                                                                          .cctwoid,
                                                                        props
                                                                          .row
                                                                          .ccthreeid,
                                                                        props
                                                                          .row
                                                                          .code,
                                                                        props
                                                                          .row
                                                                          .cctwo_code,
                                                                        props
                                                                          .row
                                                                          .ccthree_code
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Not Assigned"
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          : _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "b-modal",
                                                                        rawName:
                                                                          "v-b-modal.modal-3",
                                                                        modifiers: {
                                                                          "modal-3": true
                                                                        }
                                                                      }
                                                                    ],
                                                                    attrs: {
                                                                      variant:
                                                                        "success"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.provideVendorCompanyName(
                                                                          props
                                                                            .row
                                                                            .projectid,
                                                                          props
                                                                            .row
                                                                            .companyid,
                                                                          props
                                                                            .row
                                                                            .pwcaid,
                                                                          props
                                                                            .row
                                                                            .cconeid,
                                                                          props
                                                                            .row
                                                                            .cctwoid,
                                                                          props
                                                                            .row
                                                                            .ccthreeid,
                                                                          props
                                                                            .row
                                                                            .code,
                                                                          props
                                                                            .row
                                                                            .cctwo_code,
                                                                          props
                                                                            .row
                                                                            .ccthree_code
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa fa-user",
                                                                      staticStyle: {
                                                                        "margin-right":
                                                                          "5px"
                                                                      }
                                                                    }),
                                                                    _vm._v(
                                                                      " Vendor Services\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                      ])
                                                    }
                                                  },
                                                  {
                                                    key: "total",
                                                    fn: function(props) {
                                                      return _c("div", {}, [
                                                        _vm._v(
                                                          "\n                      $" +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                props.row.total
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ])
                                                    }
                                                  },
                                                  {
                                                    key: "net",
                                                    fn: function(props) {
                                                      return _c("div", {}, [
                                                        _vm._v(
                                                          "\n                      $"
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            class: {
                                                              green:
                                                                props.row
                                                                  .budget >
                                                                props.row.total,
                                                              danger:
                                                                props.row
                                                                  .budget <
                                                                props.row.total
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.formatPrice(
                                                                    props.row
                                                                      .budget -
                                                                      props.row
                                                                        .total
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    }
                                                  },
                                                  {
                                                    key: "budget",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      var update = ref.update
                                                      var setEditing =
                                                        ref.setEditing
                                                      var isEditing =
                                                        ref.isEditing
                                                      var revertValue =
                                                        ref.revertValue
                                                      return _c(
                                                        "div",
                                                        {
                                                          attrs: {
                                                            id: "budget-div"
                                                          }
                                                        },
                                                        [
                                                          !isEditing()
                                                            ? _c("span", [
                                                                _c("a", [
                                                                  _vm._v(
                                                                    "$" +
                                                                      _vm._s(
                                                                        row.budget
                                                                          ? _vm.formatPrice(
                                                                              row.budget
                                                                            )
                                                                          : 0.0
                                                                      )
                                                                  )
                                                                ]),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-edit",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return setEditing(
                                                                        true
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              ])
                                                            : _c(
                                                                "span",
                                                                [
                                                                  _c("input", {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          row.budget,
                                                                        expression:
                                                                          "row.budget"
                                                                      }
                                                                    ],
                                                                    attrs: {
                                                                      type:
                                                                        "text"
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        row.budget
                                                                    },
                                                                    on: {
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        ) {
                                                                          return
                                                                        }
                                                                        _vm.$set(
                                                                          row,
                                                                          "budget",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      }
                                                                    }
                                                                  }),
                                                                  row.importBudgetid ==
                                                                    null ||
                                                                  row
                                                                    .company_name
                                                                    .length > 0
                                                                    ? _c(
                                                                        "b-button",
                                                                        {
                                                                          attrs: {
                                                                            variant:
                                                                              "primary",
                                                                            id:
                                                                              "budget-btn-save"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              update(
                                                                                row.budget
                                                                              )
                                                                              setEditing(
                                                                                false
                                                                              )
                                                                              _vm.updateBugetForVenor(
                                                                                row.projectid,
                                                                                row.companyid,
                                                                                row.pwcaid,
                                                                                row.cconeid,
                                                                                row.cctwoid,
                                                                                row.ccthreeid,
                                                                                row.budget,
                                                                                row.id
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                          ✔"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "b-button",
                                                                        {
                                                                          attrs: {
                                                                            variant:
                                                                              "primary",
                                                                            id:
                                                                              "budget-btn-save"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              update(
                                                                                row.budget
                                                                              )
                                                                              setEditing(
                                                                                false
                                                                              )
                                                                              _vm.updateBugetForImportedItems(
                                                                                {
                                                                                  importBudgetid:
                                                                                    row.importBudgetid,
                                                                                  budget:
                                                                                    row.budget,
                                                                                  i:
                                                                                    row.id
                                                                                }
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                          ✔"
                                                                          )
                                                                        ]
                                                                      ),
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      attrs: {
                                                                        variant:
                                                                          "danger",
                                                                        id:
                                                                          "budget-btn-danger"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          revertValue()
                                                                          setEditing(
                                                                            false
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "X\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                        ]
                                                      )
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1680894921
                                              )
                                            },
                                            [
                                              _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    float: "right"
                                                  },
                                                  attrs: {
                                                    slot: "afterFilter"
                                                  },
                                                  slot: "afterFilter"
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "20px"
                                                      }
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          " AFE/ PO Budget"
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        " : $" +
                                                          _vm._s(
                                                            _vm.formatPrice(
                                                              _vm.totalBudget
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "20px"
                                                      }
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          "AFE/ PO Actual "
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        " : $" +
                                                          _vm._s(
                                                            _vm.formatPrice(
                                                              _vm.actualTotal
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "20px"
                                                      }
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(" AFE/ PO Net ")
                                                      ]),
                                                      _vm._v(
                                                        " : $" +
                                                          _vm._s(
                                                            _vm.formatPrice(
                                                              _vm.totalnet
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { staticClass: "fa fa-edit" }),
                            _vm._v(" Service Estimates\n            ")
                          ]),
                          _c("h4", { staticClass: "mt-2" }, [
                            _c("i", { staticClass: "fa fa-edit" }),
                            _vm._v(" Service Estimates - Line Item Per AFE "),
                            _vm.$route.meta.is_resource
                              ? _c("span", [_vm._v(" / Cost Center")])
                              : _vm._e(),
                            _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value:
                                    "<p>1. Download CSV Estimates template first.</p><p>2. Fill the Estimates for service lines as they appear in the template.</p><p>3. Upload saved template.</p>",
                                  expression:
                                    "'<p>1. Download CSV Estimates template first.</p><p>2. Fill the Estimates for service lines as they appear in the template.</p><p>3. Upload saved template.</p>'"
                                }
                              ],
                              staticClass: "ml-2 fa fa fa-question-circle"
                            })
                          ]),
                          _c("hr"),
                          _c("div", { staticClass: "row mb-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-6 col-xl-6 mt-3" },
                              [
                                _c("h5", [
                                  _vm._v("Select AFE "),
                                  _vm.$route.meta.is_resource
                                    ? _c("span", [_vm._v(" / Cost Center")])
                                    : _vm._e()
                                ]),
                                _c("multiselect", {
                                  attrs: {
                                    placeholder: "Search or add",
                                    "tag-placeholder": "Search or add afe",
                                    "track-by": "pwcaid",
                                    options: _vm.wtsAfeData,
                                    multiple: false,
                                    "close-on-select": true,
                                    "clear-on-select": false,
                                    "preserve-search": true,
                                    taggable: false,
                                    label: "afenum",
                                    "preselect-first": true
                                  },
                                  on: {
                                    input: function($event) {
                                      _vm.getVendorFromAfeForServiceEstimate(),
                                        (_vm.vendorEstimates = {})
                                    }
                                  },
                                  model: {
                                    value: _vm.afes,
                                    callback: function($$v) {
                                      _vm.afes = $$v
                                    },
                                    expression: "afes"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-6 col-xl-6 mt-3" },
                              [
                                _c("h5", [_vm._v("Select Vendors")]),
                                _c("multiselect", {
                                  attrs: {
                                    placeholder: "Search or add vendor",
                                    "tag-placeholder": "Search or add vendor",
                                    "track-by": "vid",
                                    options: _vm.vendorListServiceEstimate,
                                    multiple: false,
                                    "close-on-select": true,
                                    "clear-on-select": false,
                                    "preserve-search": true,
                                    taggable: false,
                                    label: "company_name",
                                    "preselect-first": true
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.getVendorServiceForServiceEstimate()
                                    }
                                  },
                                  model: {
                                    value: _vm.vendorEstimates,
                                    callback: function($$v) {
                                      _vm.vendorEstimates = $$v
                                    },
                                    expression: "vendorEstimates"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _vm.vendorListServiceDetailsServiceEstimate.length >
                            0
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 my-4" },
                                  [
                                    _c("h3", { staticClass: "d-inline" }, [
                                      _vm._v(
                                        _vm._s(_vm.vendorEstimates.company_name)
                                      )
                                    ]),
                                    _c(
                                      "vue-json-to-csv",
                                      {
                                        staticClass:
                                          "pull-right mr-2 btn btn-sm btn-blue",
                                        attrs: {
                                          labels: _vm.labels,
                                          "csv-title": "Import Estimates",
                                          "json-data": _vm.sendFilteredNew()
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Download CSV Estimates Template\n                  "
                                        ),
                                        _c("i", {
                                          staticClass: "fa fa-download"
                                        })
                                      ]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName:
                                              "v-b-modal.importEstimates",
                                            modifiers: { importEstimates: true }
                                          }
                                        ],
                                        staticClass: "pull-right mr-2 btn-sm",
                                        attrs: { variant: "success" }
                                      },
                                      [
                                        _vm._v(
                                          " Import Estimates\n                  From CSV"
                                        ),
                                        _c("i", { staticClass: "fa fa-plus" })
                                      ]
                                    ),
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Search For Service:")
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-md-6",
                                          attrs: { id: "search_estimate" }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.searchBox,
                                                expression: "searchBox"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Search"
                                            },
                                            domProps: { value: _vm.searchBox },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.searchBox =
                                                  $event.target.value
                                              }
                                            }
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              on: {
                                                click: function($event) {
                                                  return _vm.Reload_getVendorFromAfeForServiceEstimate()
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-sync-alt"
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]),
                                    !_vm.lodash.isEmpty(
                                      _vm.vendorEstimates,
                                      true
                                    )
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "table-responsive mt-2 table-striped table-bordered"
                                          },
                                          [
                                            _c(
                                              "table",
                                              { staticClass: "table" },
                                              [
                                                _c("thead", [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "table-success"
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v("Service Name")
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            colspan: "2"
                                                          }
                                                        },
                                                        [_vm._v("UOM 1")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            colspan: "2"
                                                          }
                                                        },
                                                        [_vm._v("UOM 2")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            colspan: "2"
                                                          }
                                                        },
                                                        [_vm._v("UOM 3")]
                                                      ),
                                                      _c("td", [
                                                        _vm._v("Actual")
                                                      ]),
                                                      _c("td", [
                                                        _vm._v("Estimate")
                                                      ]),
                                                      _c("td", [
                                                        _vm._v("Difference")
                                                      ])
                                                    ]
                                                  )
                                                ]),
                                                _vm._l(
                                                  _vm.vendorListServiceDetailsServiceEstimateComputed,
                                                  function(vendorService) {
                                                    return _c(
                                                      "tbody",
                                                      {
                                                        attrs: {
                                                          id: vendorService.ID
                                                        }
                                                      },
                                                      [
                                                        _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                width: "20%"
                                                              },
                                                              attrs: {
                                                                rowspan: "3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    vendorService.servicename
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("br"),
                                                              vendorService.isflexible ===
                                                              "Y"
                                                                ? _c(
                                                                    "b-badge",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "b-popover",
                                                                          rawName:
                                                                            "v-b-popover.hover",
                                                                          value:
                                                                            "Flexible service have no certain pricing and uom.",
                                                                          expression:
                                                                            "'Flexible service have no certain pricing and uom.'",
                                                                          modifiers: {
                                                                            hover: true
                                                                          }
                                                                        }
                                                                      ],
                                                                      staticStyle: {
                                                                        color:
                                                                          "white"
                                                                      },
                                                                      attrs: {
                                                                        title:
                                                                          "Information",
                                                                        variant:
                                                                          "info"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Flexible Service"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                colspan: "2"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          UOM: "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    vendorService.uom1
                                                                  )
                                                                )
                                                              ]),
                                                              _c("br"),
                                                              _vm._v(
                                                                "\n                          Price per unit: "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formatPrice(
                                                                        vendorService.pu1
                                                                      )
                                                                    )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                colspan: "2"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          UOM: "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      vendorService.uom2
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]),
                                                              _c("br"),
                                                              _vm._v(
                                                                "\n                          Price per unit: "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formatPrice(
                                                                        vendorService.pu2
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                colspan: "2"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          UOM: "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    vendorService.uom3
                                                                  ) + " "
                                                                )
                                                              ]),
                                                              _c("br"),
                                                              _vm._v(
                                                                "\n                          Price per unit: "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formatPrice(
                                                                        vendorService.pu3
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                rowspan: "3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "$\n                          "
                                                              ),
                                                              vendorService.isflexible ===
                                                              "N"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          (
                                                                            vendorService.uom1total +
                                                                            vendorService.uom2total +
                                                                            vendorService.uom3total
                                                                          ).toFixed(
                                                                            2
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          vendorService.pu.toFixed(
                                                                            2
                                                                          )
                                                                        ) +
                                                                        "\n                          "
                                                                    )
                                                                  ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                rowspan: "3"
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.vendorEstimatedServiceListServiceEstimate,
                                                              function(
                                                                estimatedService
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  [
                                                                    estimatedService.serviceid ==
                                                                      vendorService.serviceid &&
                                                                    estimatedService.afenum ==
                                                                      _vm.afes
                                                                        .afenum
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  (
                                                                                    estimatedService.qty1 *
                                                                                      vendorService.pu1 +
                                                                                    estimatedService.qty2 *
                                                                                      vendorService.pu2 +
                                                                                    estimatedService.qty3 *
                                                                                      vendorService.pu3
                                                                                  ).toFixed(
                                                                                    2
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                rowspan: "3"
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.vendorEstimatedServiceListServiceEstimate,
                                                              function(
                                                                estimatedService
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  [
                                                                    estimatedService.serviceid ==
                                                                      vendorService.serviceid &&
                                                                    estimatedService.afenum ==
                                                                      _vm.afes
                                                                        .afenum
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            (
                                                                              vendorService.uom1total +
                                                                              vendorService.uom2total +
                                                                              vendorService.uom3total
                                                                            ).toFixed(
                                                                              2
                                                                            ) -
                                                                              (
                                                                                estimatedService.qty1 *
                                                                                  vendorService.pu1 +
                                                                                estimatedService.qty2 *
                                                                                  vendorService.pu2 +
                                                                                estimatedService.qty3 *
                                                                                  vendorService.pu3
                                                                              ).toFixed(
                                                                                2
                                                                              ) <
                                                                            0
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      color:
                                                                                        "red",
                                                                                      "font-weight":
                                                                                        "bold"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                " +
                                                                                        _vm._s(
                                                                                          (
                                                                                            vendorService.uom1total +
                                                                                            vendorService.uom2total +
                                                                                            vendorService.uom3total -
                                                                                            (estimatedService.qty1 *
                                                                                              vendorService.pu1 +
                                                                                              estimatedService.qty2 *
                                                                                                vendorService.pu2 +
                                                                                              estimatedService.qty3 *
                                                                                                vendorService.pu3)
                                                                                          ).toFixed(
                                                                                            2
                                                                                          )
                                                                                        ) +
                                                                                        "\n                              "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      color:
                                                                                        "green",
                                                                                      "font-weight":
                                                                                        "bold"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                " +
                                                                                        _vm._s(
                                                                                          (
                                                                                            vendorService.uom1total +
                                                                                            vendorService.uom2total +
                                                                                            vendorService.uom3total -
                                                                                            (estimatedService.qty1 *
                                                                                              vendorService.pu1 +
                                                                                              estimatedService.qty2 *
                                                                                                vendorService.pu2 +
                                                                                              estimatedService.qty3 *
                                                                                                vendorService.pu3)
                                                                                          ).toFixed(
                                                                                            2
                                                                                          )
                                                                                        ) +
                                                                                        "\n                              "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        ]),
                                                        _c(
                                                          "tr",
                                                          {
                                                            staticClass:
                                                              "table-info"
                                                          },
                                                          [
                                                            _c("td", [
                                                              _vm._v("Actual")
                                                            ]),
                                                            _c("td", [
                                                              _vm._v("Estimate")
                                                            ]),
                                                            _c("td", [
                                                              _vm._v("Actual")
                                                            ]),
                                                            _c("td", [
                                                              _vm._v("Estimate")
                                                            ]),
                                                            _c("td", [
                                                              _vm._v("Actual")
                                                            ]),
                                                            _c("td", [
                                                              _vm._v("Estimate")
                                                            ])
                                                          ]
                                                        ),
                                                        _c("tr", [
                                                          _c("td", [
                                                            vendorService.uom1total !=
                                                            0
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    "\n                            Quantity: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          vendorService.uom1qtn
                                                                        )
                                                                    )
                                                                  ]),
                                                                  _c("br"),
                                                                  _vm._v(
                                                                    "\n                            Total: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          vendorService.uom1total.toFixed(
                                                                            2
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                ])
                                                              : _c("div", [
                                                                  _vm._v(
                                                                    "\n                            Quantity: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " NA"
                                                                    )
                                                                  ]),
                                                                  _c("br"),
                                                                  _vm._v(
                                                                    "\n                            Total: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " NA "
                                                                    )
                                                                  ])
                                                                ])
                                                          ]),
                                                          _c("td", [
                                                            vendorService.uom1 !=
                                                            null
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _vm._l(
                                                                      _vm.vendorEstimatedServiceListServiceEstimate,
                                                                      function(
                                                                        estimatedService
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          [
                                                                            estimatedService.serviceid ==
                                                                              vendorService.serviceid &&
                                                                            estimatedService.afenum ==
                                                                              _vm
                                                                                .afes
                                                                                .afenum
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                Quantity: "
                                                                                    ),
                                                                                    _c(
                                                                                      "b",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              estimatedService.qty1
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "br"
                                                                                    ),
                                                                                    _vm._v(
                                                                                      "\n                                Total: "
                                                                                    ),
                                                                                    _c(
                                                                                      "b",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              (
                                                                                                estimatedService.qty1 *
                                                                                                vendorService.pu1
                                                                                              ).toFixed(
                                                                                                2
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "b-modal",
                                                                            rawName:
                                                                              "v-b-modal.addEstimateUom1",
                                                                            modifiers: {
                                                                              addEstimateUom1: true
                                                                            }
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "btn primary",
                                                                        staticStyle: {
                                                                          background:
                                                                            "#73C686",
                                                                          color:
                                                                            "white"
                                                                        },
                                                                        attrs: {
                                                                          type:
                                                                            "button"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.getNoUoms(
                                                                              vendorService
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "+"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              : _vm._e()
                                                          ]),
                                                          _c("td", [
                                                            vendorService.uom2total !=
                                                            0
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    "\n                            Quantity: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          vendorService.uom2qtn
                                                                        )
                                                                    )
                                                                  ]),
                                                                  _c("br"),
                                                                  _vm._v(
                                                                    "\n                            Total: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          vendorService.uom2total.toFixed(
                                                                            2
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                ])
                                                              : _c("div", [
                                                                  _vm._v(
                                                                    "\n                            Quantity: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " NA"
                                                                    )
                                                                  ]),
                                                                  _c("br"),
                                                                  _vm._v(
                                                                    "\n                            Total: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " NA "
                                                                    )
                                                                  ])
                                                                ])
                                                          ]),
                                                          _c("td", [
                                                            vendorService.uom2 !=
                                                            null
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _vm._l(
                                                                      _vm.vendorEstimatedServiceListServiceEstimate,
                                                                      function(
                                                                        estimatedService
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          [
                                                                            estimatedService.serviceid ==
                                                                              vendorService.serviceid &&
                                                                            estimatedService.afenum ==
                                                                              _vm
                                                                                .afes
                                                                                .afenum
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                Quantity: "
                                                                                    ),
                                                                                    _c(
                                                                                      "b",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              estimatedService.qty2
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "br"
                                                                                    ),
                                                                                    _vm._v(
                                                                                      "\n                                Total: "
                                                                                    ),
                                                                                    _c(
                                                                                      "b",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              (
                                                                                                estimatedService.qty2 *
                                                                                                vendorService.pu2
                                                                                              ).toFixed(
                                                                                                2
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    _c("div", [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "b-modal",
                                                                              rawName:
                                                                                "v-b-modal.addEstimateUom2",
                                                                              modifiers: {
                                                                                addEstimateUom2: true
                                                                              }
                                                                            }
                                                                          ],
                                                                          staticClass:
                                                                            "btn primary",
                                                                          staticStyle: {
                                                                            background:
                                                                              "#73C686",
                                                                            color:
                                                                              "white"
                                                                          },
                                                                          attrs: {
                                                                            type:
                                                                              "button"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.getNoUoms(
                                                                                vendorService
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "+"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ])
                                                                  ],
                                                                  2
                                                                )
                                                              : _vm._e()
                                                          ]),
                                                          _c("td", [
                                                            vendorService.uom3total !=
                                                            0
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    "\n                            Quantity: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          vendorService.uom3qtn
                                                                        )
                                                                    )
                                                                  ]),
                                                                  _c("br"),
                                                                  _vm._v(
                                                                    "\n                            Total: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          vendorService.uom3total.toFixed(
                                                                            2
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                ])
                                                              : _c("div", [
                                                                  _vm._v(
                                                                    "\n                            Quantity: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " NA"
                                                                    )
                                                                  ]),
                                                                  _c("br"),
                                                                  _vm._v(
                                                                    "\n                            Total: "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " NA "
                                                                    )
                                                                  ])
                                                                ])
                                                          ]),
                                                          _c("td", [
                                                            vendorService.uom3 !=
                                                            null
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _vm._l(
                                                                      _vm.vendorEstimatedServiceListServiceEstimate,
                                                                      function(
                                                                        estimatedService
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          [
                                                                            estimatedService.serviceid ==
                                                                              vendorService.serviceid &&
                                                                            estimatedService.afenum ==
                                                                              _vm
                                                                                .afes
                                                                                .afenum
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                Quantity: "
                                                                                    ),
                                                                                    _c(
                                                                                      "b",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              estimatedService.qty3
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "br"
                                                                                    ),
                                                                                    _vm._v(
                                                                                      "\n                                Total: "
                                                                                    ),
                                                                                    _c(
                                                                                      "b",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              (
                                                                                                estimatedService.qty3 *
                                                                                                vendorService.pu3
                                                                                              ).toFixed(
                                                                                                2
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "b-modal",
                                                                            rawName:
                                                                              "v-b-modal.addEstimateUom3",
                                                                            modifiers: {
                                                                              addEstimateUom3: true
                                                                            }
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "btn primary",
                                                                        staticStyle: {
                                                                          background:
                                                                            "#73C686",
                                                                          color:
                                                                            "white"
                                                                        },
                                                                        attrs: {
                                                                          type:
                                                                            "button"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.getNoUoms(
                                                                              vendorService
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "+"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              : _vm._e()
                                                          ])
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _c(
                            "b-modal",
                            {
                              ref: "addEstimateUom1",
                              attrs: {
                                id: "addEstimateUom1",
                                title: "Add/Update Estimate 1",
                                "title-tag": "h2",
                                centered: "",
                                size: "lg",
                                "hide-footer": ""
                              }
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-8" }, [
                                  _c("h5", [_vm._v("Enter quanity:")])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-12 form-group" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.uom1Quantity,
                                          expression: "uom1Quantity"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.uom1Quantity },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.uom1Quantity = $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "success",
                                          type: "submit",
                                          block: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.createEstimateUom1()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v("Add "),
                                        _c("i", { staticClass: "fa fa-plus" })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          ),
                          _c(
                            "b-modal",
                            {
                              ref: "addEstimateUom2",
                              attrs: {
                                id: "addEstimateUom2",
                                title: "Add/Update Estimate 2",
                                "title-tag": "h2",
                                centered: "",
                                size: "lg",
                                "hide-footer": ""
                              }
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-8" }, [
                                  _c("h5", [_vm._v("Enter quanity:")])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-12 form-group" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.uom2Quantity,
                                          expression: "uom2Quantity"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.uom2Quantity },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.uom2Quantity = $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "success",
                                          type: "submit",
                                          block: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.createEstimateUom2()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v("Add "),
                                        _c("i", { staticClass: "fa fa-plus" })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          ),
                          _c(
                            "b-modal",
                            {
                              ref: "addEstimateUom3",
                              attrs: {
                                id: "addEstimateUom3",
                                title: "Add/Update Estimate 3",
                                "title-tag": "h2",
                                centered: "",
                                size: "lg",
                                "hide-footer": ""
                              }
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-8" }, [
                                  _c("h5", [_vm._v("Enter quanity:")])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-12 form-group" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.uom3Quantity,
                                          expression: "uom3Quantity"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.uom3Quantity },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.uom3Quantity = $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "success",
                                          type: "submit",
                                          block: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.createEstimateUom3()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v("Add "),
                                        _c("i", { staticClass: "fa fa-plus" })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          ),
                          _c(
                            "b-modal",
                            {
                              ref: "addEstimate",
                              attrs: {
                                id: "addEstimate",
                                title: "Add New Estimate",
                                "title-tag": "h2",
                                centered: "",
                                size: "lg",
                                "hide-footer": ""
                              }
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-8" }, [
                                  _c("h5", [_vm._v(_vm._s(_vm.uom1))])
                                ]),
                                _vm.uom1
                                  ? _c(
                                      "div",
                                      { staticClass: "col-md-12 form-group" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.uom1Quantity,
                                              expression: "uom1Quantity"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.uom1Quantity },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.uom1Quantity =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-8" }, [
                                  _c("h5", [_vm._v(_vm._s(_vm.uom2))])
                                ]),
                                _vm.uom2
                                  ? _c(
                                      "div",
                                      { staticClass: "col-md-12 form-group" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.uom2Quantity,
                                              expression: "uom2Quantity"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.uom2Quantity },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.uom2Quantity =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-8" }, [
                                  _c("h5", [_vm._v(_vm._s(_vm.uom3))])
                                ]),
                                _vm.uom3
                                  ? _c(
                                      "div",
                                      { staticClass: "col-md-12 form-group" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.uom3Quantity,
                                              expression: "uom3Quantity"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.uom3Quantity },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.uom3Quantity =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "success",
                                          type: "submit",
                                          block: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.createEstimate()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v("Add "),
                                        _c("i", { staticClass: "fa fa-plus" })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          ),
                          _c(
                            "b-modal",
                            {
                              ref: "importEstimates",
                              attrs: {
                                id: "importEstimates",
                                "hide-footer": "",
                                title: "Import Estimates",
                                "title-tag": "h2",
                                centered: "",
                                size: "lg"
                              },
                              on: {
                                shown: function($event) {
                                  return _vm.clearImport()
                                },
                                hidden: function($event) {
                                  return _vm.getServices(true)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "parse mt-2 mb-4 row" },
                                [
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("h5", [_vm._v("CSV Files Only")]),
                                    _c("input", {
                                      ref: "csvFile",
                                      staticClass: "mt-2",
                                      attrs: { id: "fileInput", type: "file" },
                                      on: { change: _vm.upload }
                                    })
                                  ]),
                                  _vm.doc.length > 0
                                    ? _c("div", { staticClass: "col-md-6" }, [
                                        _c("h5", [_vm._v("Import")]),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: {
                                              click: function($event) {
                                                return _vm.importEstimates(
                                                  _vm.doc
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Import")]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.successServices.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "col-md-12 mt-4" },
                                        [
                                          _c("h5", [
                                            _vm._v("Successfully Imported: ")
                                          ]),
                                          _c(
                                            "ul",
                                            _vm._l(
                                              _vm.successServices,
                                              function(success) {
                                                return _c("li", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(success) +
                                                      "\n                    "
                                                  )
                                                ])
                                              }
                                            ),
                                            0
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.serviceErrors.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "col-md-12 mt-4" },
                                        [
                                          _c("h5", [_vm._v("Unsuccessful: ")]),
                                          _c(
                                            "ul",
                                            _vm._l(_vm.serviceErrors, function(
                                              error
                                            ) {
                                              return _c("li", [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(error) +
                                                    "\n                    "
                                                )
                                              ])
                                            }),
                                            0
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { staticClass: "fa fa-tag" }),
                            _vm._v(" Cost Code Chain\n            ")
                          ]),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("h4", { staticClass: "d-inline" }, [
                                    _vm._v("Cost Code Chain")
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.importCostCode",
                                          modifiers: { importCostCode: true }
                                        }
                                      ],
                                      staticClass:
                                        "btn float-right mr-2 btn-success",
                                      attrs: {
                                        title: "Upload Template",
                                        type: "button"
                                      }
                                    },
                                    [_vm._v("Upload from Template")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.addCostCode",
                                          modifiers: { addCostCode: true }
                                        }
                                      ],
                                      staticClass:
                                        "btn float-right mr-2 btn-success",
                                      attrs: {
                                        title: "Add Manual",
                                        type: "button"
                                      }
                                    },
                                    [
                                      _vm._v(" Add Manual "),
                                      _c("i", { staticClass: "fa fa-plus" })
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.modal-1",
                                          modifiers: { "modal-1": true }
                                        }
                                      ],
                                      staticClass:
                                        "btn float-right mr-2 btn-danger",
                                      attrs: {
                                        title: "Delete All Cost Code",
                                        type: "button"
                                      }
                                    },
                                    [_vm._v("Delete All")]
                                  ),
                                  _c("br")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-modal",
                            {
                              attrs: { id: "modal-1", title: "Are you sure ?" },
                              on: { ok: _vm.deleteAllCostCodeOnClick }
                            },
                            [
                              _c("div", [
                                _c("p", [
                                  _vm._v("You want to delete all cost code ? ")
                                ])
                              ])
                            ]
                          ),
                          _c("v-server-table", {
                            ref: "costCodeTable",
                            staticClass: "statusTable",
                            attrs: {
                              columns: _vm.columnsCostCodeServices,
                              options: _vm.optionsCostCodeServices,
                              id: "dataTable"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "edit",
                                fn: function(props) {
                                  return _c("div", {}, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.editCostCode",
                                                modifiers: {
                                                  editCostCode: true
                                                }
                                              }
                                            ],
                                            staticClass: "btn-sm",
                                            attrs: { variant: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.editCostCodeOnClick(
                                                  props.row.costcode1,
                                                  props.row.costcode2,
                                                  props.row.costcode3,
                                                  props.row.optionaldescription
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-edit"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { margin: "0px 5px" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.deleteCostCode",
                                                modifiers: {
                                                  deleteCostCode: true
                                                }
                                              }
                                            ],
                                            staticClass: "btn-sm",
                                            attrs: { variant: "danger" },
                                            on: {
                                              click: function($event) {
                                                return _vm.editCostCodeOnClick(
                                                  props.row.costcode1,
                                                  props.row.costcode2,
                                                  props.row.costcode3,
                                                  props.row.optionaldescription
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-trash",
                                              attrs: { "aria-hidden": "true" }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                }
                              }
                            ])
                          }),
                          _c(
                            "b-modal",
                            {
                              ref: "importCostCode",
                              attrs: {
                                id: "importCostCode",
                                "hide-footer": "",
                                title: "Import Cost Codes",
                                "title-tag": "h2",
                                centered: "",
                                size: "lg"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "parse mt-2 mb-4 row" },
                                [
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("h5", [_vm._v("CSV Files Only")]),
                                    _c("input", {
                                      ref: "csvFile",
                                      staticClass: "mt-2",
                                      attrs: {
                                        id: "fileInput",
                                        type: "file",
                                        accept:
                                          ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      },
                                      on: { change: _vm.upload }
                                    })
                                  ]),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("h5", [_vm._v("Template")]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-primary",
                                        attrs: {
                                          href:
                                            "/templates/Cost-Codes-Import-Template.csv"
                                        }
                                      },
                                      [
                                        _vm._v("Download Template "),
                                        _c("i", { staticClass: "fa fa-file" })
                                      ]
                                    )
                                  ]),
                                  _vm.doc.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "col-md-6",
                                          staticStyle: {
                                            margin: "20px 0px 10px 0px"
                                          }
                                        },
                                        [
                                          _c("h5", [_vm._v("Import")]),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              on: {
                                                click: function($event) {
                                                  _vm.importFile(_vm.doc)
                                                  _vm.$bvModal.hide(
                                                    "importCostCode"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Import")]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "addCostCode",
                      attrs: {
                        id: "addCostCode",
                        title: "Add CostCode",
                        "title-tag": "h2",
                        centered: "",
                        size: "lg",
                        "hide-footer": ""
                      },
                      on: {
                        shown: function($event) {
                          return _vm.clearErrors()
                        },
                        hidden: function($event) {
                          return _vm.clearCostCodeForm()
                        }
                      }
                    },
                    [
                      _c(
                        "form",
                        {
                          staticClass: "mb-3",
                          attrs: { "data-vv-scope": "addCostCode" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.addCostCodeFun("addCostCode")
                            }
                          }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("label", [
                                    _c("span", [_vm._v("Cost Code 1")])
                                  ]),
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-tag"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "costcode1",
                                          placeholder: "Code 1"
                                        },
                                        model: {
                                          value: _vm.costcode1,
                                          callback: function($$v) {
                                            _vm.costcode1 = $$v
                                          },
                                          expression: "costcode1"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has(
                                          "addCostCode.costcode1"
                                        ),
                                        expression:
                                          "errors.has('addCostCode.costcode1')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "addCostCode.costcode1"
                                          ),
                                          expression:
                                            "errors.has('addCostCode.costcode1')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        "The cost code 1 is\n                  required."
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("label", [
                                    _c("span", [_vm._v("Cost Code 2")])
                                  ]),
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-tag"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "costcode2",
                                          placeholder: "Code 2"
                                        },
                                        model: {
                                          value: _vm.costcode2,
                                          callback: function($$v) {
                                            _vm.costcode2 = $$v
                                          },
                                          expression: "costcode2"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has(
                                          "addCostCode.costcode2"
                                        ),
                                        expression:
                                          "errors.has('addCostCode.costcode2')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "addCostCode.costcode2"
                                          ),
                                          expression:
                                            "errors.has('addCostCode.costcode2')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        "The cost code 2 is\n                  required."
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("label", [
                                    _c("span", [_vm._v("Cost Code 3")])
                                  ]),
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-tag"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "costcode3",
                                          placeholder: "Code 3"
                                        },
                                        model: {
                                          value: _vm.costcode3,
                                          callback: function($$v) {
                                            _vm.costcode3 = $$v
                                          },
                                          expression: "costcode3"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has(
                                          "addCostCode.costcode3"
                                        ),
                                        expression:
                                          "errors.has('addCostCode.costcode3')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "addCostCode.costcode3"
                                          ),
                                          expression:
                                            "errors.has('addCostCode.costcode3')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        "The cost code3 is\n                  required."
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("label", [
                                    _c("span", [_vm._v("Optional Description")])
                                  ]),
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-tag"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "optionaldescription",
                                          placeholder: "Description"
                                        },
                                        model: {
                                          value: _vm.optionaldescription,
                                          callback: function($$v) {
                                            _vm.optionaldescription = $$v
                                          },
                                          expression: "optionaldescription"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-3 float-right",
                                  attrs: { md: "12" }
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-2 mb-2",
                                      attrs: {
                                        variant: "success",
                                        type: "submit",
                                        block: ""
                                      }
                                    },
                                    [_vm._v("Add CostCode")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "editCostCode",
                      attrs: {
                        id: "editCostCode",
                        title: "Edit CostCode",
                        "title-tag": "h2",
                        centered: "",
                        size: "lg",
                        "hide-footer": ""
                      },
                      on: {
                        shown: function($event) {
                          return _vm.clearErrors()
                        },
                        hidden: function($event) {
                          return _vm.clearCostCodeForm()
                        }
                      }
                    },
                    [
                      _c(
                        "form",
                        {
                          staticClass: "mb-3",
                          attrs: { "data-vv-scope": "editCostCode" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.editCostCodeFun("editCostCode")
                            }
                          }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("label", [
                                    _c("span", [_vm._v("Cost Code 1")])
                                  ]),
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-tag"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        ref: "costcode1",
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "costcode1",
                                          placeholder: "Code 1"
                                        },
                                        model: {
                                          value: _vm.costcode1,
                                          callback: function($$v) {
                                            _vm.costcode1 = $$v
                                          },
                                          expression: "costcode1"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has(
                                          "editCostCode.costcode1"
                                        ),
                                        expression:
                                          "errors.has('editCostCode.costcode1')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "editCostCode.costcode1"
                                          ),
                                          expression:
                                            "errors.has('editCostCode.costcode1')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        "The cost code1 is\n                  required."
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("label", [
                                    _c("span", [_vm._v("Cost Code 2")])
                                  ]),
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-tag"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "costcode2",
                                          placeholder: "Code 2"
                                        },
                                        model: {
                                          value: _vm.costcode2,
                                          callback: function($$v) {
                                            _vm.costcode2 = $$v
                                          },
                                          expression: "costcode2"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has(
                                          "editCostCode.costcode2"
                                        ),
                                        expression:
                                          "errors.has('editCostCode.costcode2')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "editCostCode.costcode2"
                                          ),
                                          expression:
                                            "errors.has('editCostCode.costcode2')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        "The cost code2 is\n                  required."
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("label", [
                                    _c("span", [_vm._v("Cost Code 3")])
                                  ]),
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-tag"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "costcode3",
                                          placeholder: "Code 3"
                                        },
                                        model: {
                                          value: _vm.costcode3,
                                          callback: function($$v) {
                                            _vm.costcode3 = $$v
                                          },
                                          expression: "costcode3"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has(
                                          "editCostCode.costcode3"
                                        ),
                                        expression:
                                          "errors.has('editCostCode.costcode3')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "editCostCode.costcode3"
                                          ),
                                          expression:
                                            "errors.has('editCostCode.costcode3')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        "The cost code3 is\n                  required."
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("label", [
                                    _c("span", [_vm._v("Optional Description")])
                                  ]),
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-tag"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "optionaldescription",
                                          placeholder: "Description"
                                        },
                                        model: {
                                          value: _vm.optionaldescription,
                                          callback: function($$v) {
                                            _vm.optionaldescription = $$v
                                          },
                                          expression: "optionaldescription"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-3 float-right",
                                  attrs: { md: "12" }
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-2 mb-2",
                                      attrs: {
                                        variant: "success",
                                        type: "submit",
                                        block: ""
                                      }
                                    },
                                    [_vm._v("Edit CostCode")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "deleteCostCode",
                      attrs: {
                        id: "deleteCostCode",
                        title:
                          "Are you sure that you want to delete this cost code?",
                        "title-tag": "h2",
                        centered: "",
                        size: "lg",
                        "hide-footer": ""
                      },
                      on: {
                        shown: function($event) {
                          return _vm.clearErrors()
                        },
                        hidden: function($event) {
                          return _vm.clearCostCodeForm()
                        }
                      }
                    },
                    [
                      _c(
                        "form",
                        {
                          staticClass: "mb-3",
                          attrs: { "data-vv-scope": "deleteCostCode" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.deleteCostCodeFun("deleteCostCode")
                            }
                          }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-3 float-right",
                                  attrs: { md: "6" }
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-2 mb-2",
                                      attrs: {
                                        variant: "success",
                                        type: "submit",
                                        block: ""
                                      }
                                    },
                                    [_vm._v("Yes")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-3 float-right",
                                  attrs: { md: "6" }
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-2 mb-2",
                                      attrs: { variant: "primary", block: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$bvModal.hide(
                                            "deleteCostCode"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("No\n                ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "b-modal",
            {
              ref: "importCCVendorModal",
              attrs: {
                id: "importCCVendorModal",
                title: "Select Vendor Service",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                hidden: function($event) {
                  _vm.loadCostCodeChain(),
                    (_vm.progressBar = false),
                    (_vm.importNewVendorServices = false)
                },
                shown: function($event) {
                  ;(_vm.importNewVendorServices = true),
                    (_vm.loadingCount = 0),
                    (_vm.progressBarServicesCount = 0),
                    (_vm.progressBar = false)
                }
              }
            },
            [
              _c(
                "b-row",
                { staticStyle: { "max-height": "500px", overflow: "scroll" } },
                _vm._l(_vm.vendorServices, function(service) {
                  return _c(
                    "div",
                    { key: service.vasid, staticClass: "col-md-6" },
                    [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.vendorSelectedServices,
                              expression: "vendorSelectedServices"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            value: service,
                            checked: Array.isArray(_vm.vendorSelectedServices)
                              ? _vm._i(_vm.vendorSelectedServices, service) > -1
                              : _vm.vendorSelectedServices
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.vendorSelectedServices,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = service,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.vendorSelectedServices = $$a.concat([
                                      $$v
                                    ]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.vendorSelectedServices = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.vendorSelectedServices = $$c
                              }
                            }
                          }
                        }),
                        _c("label", { staticClass: "form-check-label" }, [
                          _c("strong", [_vm._v(_vm._s(service.name))])
                        ])
                      ])
                    ]
                  )
                }),
                0
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { lg: "6" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "success",
                            disabled: _vm.loadingImport,
                            type: "submit",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.importCCChainByVendorServices(
                                _vm.vendorSelectedServices
                              )
                            }
                          }
                        },
                        [
                          _vm._v("Assign\n            Selectedss "),
                          _c("i", { staticClass: "fa fa-file-import" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { lg: "6" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "success",
                            disabled: _vm.loadingImport,
                            type: "submit",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.importCCChainByAllVendorServices()
                            }
                          }
                        },
                        [
                          _vm._v("Assign All"),
                          _c("i", { staticClass: "fa fa-file-import" })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-row", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.progressBar,
                        expression: "progressBar"
                      }
                    ],
                    staticClass: "col-md-12 mt-4"
                  },
                  [_c("h4", [_vm._v("Progress:")])]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-progress",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.progressBar,
                            expression: "progressBar"
                          }
                        ],
                        staticClass: "mt-2",
                        staticStyle: { "font-size": "0.8rem" },
                        attrs: {
                          max: _vm.progressBarServicesCount,
                          height: "2rem",
                          "show-value": ""
                        }
                      },
                      [
                        _vm.loadingCount == _vm.progressBarServicesCount
                          ? _c("b-progress-bar", {
                              attrs: {
                                value: _vm.loadingCount,
                                label:
                                  _vm.loadingCount +
                                  "/" +
                                  _vm.progressBarServicesCount +
                                  " (100%)",
                                variant: "success"
                              }
                            })
                          : _c("b-progress-bar", {
                              attrs: {
                                value: _vm.loadingCount,
                                label:
                                  _vm.loadingCount +
                                  "/" +
                                  _vm.progressBarServicesCount +
                                  " (" +
                                  (
                                    (_vm.loadingCount /
                                      _vm.progressBarServicesCount) *
                                    100
                                  ).toFixed(2) +
                                  "%" +
                                  ")",
                                variant: "success"
                              }
                            })
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.progressBar,
                            expression: "progressBar"
                          }
                        ],
                        staticClass: "mt-3"
                      },
                      [
                        _vm._v(
                          "Please do not close this window until 100% or it may be unsuccessful.\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "assignmentsVendor",
              attrs: {
                id: "assignmentsVendor",
                title: "Vendor Service Assignments",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              }
            },
            [
              _c("v-client-table", {
                attrs: {
                  columns: _vm.columnsVendorForServices,
                  data: _vm.wtsVendorServiceData,
                  options: _vm.optionsVendorForServices,
                  theme: _vm.theme,
                  id: "dataTable"
                },
                scopedSlots: _vm._u([
                  {
                    key: "status",
                    fn: function(props) {
                      return _c("div", {}, [
                        props.row.status === 0
                          ? _c("span", [
                              _c(
                                "button",
                                { staticClass: "btn btn-sm btn-success w-100" },
                                [_vm._v("Active")]
                              )
                            ])
                          : _vm._e(),
                        props.row.status !== 0
                          ? _c("span", [
                              _c(
                                "button",
                                { staticClass: "btn btn-sm btn-danger w-100" },
                                [_vm._v("Inactive")]
                              )
                            ])
                          : _vm._e()
                      ])
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "importChain",
              attrs: {
                id: "importChain",
                title: "Assign Services to the Cost Code Chain",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                hidden: function($event) {
                  return _vm.enterCC(_vm.pwcaid, _vm.afenum)
                }
              }
            },
            [
              _c("WtsChainImport", {
                attrs: {
                  is_resource: _vm.$route.meta.is_resource,
                  update: _vm.update,
                  name: _vm.vcnProp,
                  pwcaid: _vm.pwcaid,
                  existing: _vm.chainData,
                  companyid: _vm.profile.companyid
                }
              })
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "importChainFromAnotherProjectModal",
              attrs: {
                id: "importChainFromAnotherProjectModal",
                title: "Import From Another Project",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                hidden: function($event) {
                  _vm.loadCostCodeChain(), (_vm.progressBar = false)
                },
                shown: function($event) {
                  ;(_vm.loadingCount = 0),
                    (_vm.progressBarServicesCount = 0),
                    (_vm.progressBar = false)
                }
              }
            },
            [
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("h5", [_vm._v("Select Project")]),
                      _c("multiselect", {
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "Search For Project",
                          "tag-placeholder": "Search For Project",
                          "track-by": "projectid",
                          options: _vm.projectData,
                          multiple: false,
                          "close-on-select": true,
                          "clear-on-select": true,
                          "preserve-search": true,
                          taggable: true,
                          label: "projectname",
                          "preselect-first": true
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadAfesFromAnother(
                              _vm.anotherProjectid.projectid
                            )
                          }
                        },
                        model: {
                          value: _vm.anotherProjectid,
                          callback: function($$v) {
                            _vm.anotherProjectid = $$v
                          },
                          expression: "anotherProjectid"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { md: "12" } },
                    [
                      !_vm.$route.meta.is_resource
                        ? _c("h5", [_vm._v("Select AFE")])
                        : _c("h5", [_vm._v("Select Cost Center")]),
                      _c("multiselect", {
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "Search For Project",
                          "tag-placeholder": "Search For AFE",
                          "track-by": "pwcaid",
                          options: _vm.anotherAfeData,
                          multiple: false,
                          "close-on-select": true,
                          "clear-on-select": true,
                          "preserve-search": true,
                          taggable: true,
                          label: "afenum",
                          "preselect-first": true
                        },
                        model: {
                          value: _vm.anotherAfe,
                          callback: function($$v) {
                            _vm.anotherAfe = $$v
                          },
                          expression: "anotherAfe"
                        }
                      })
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "mt-3", attrs: { md: "12" } }, [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          "**Please import services before importing from another project."
                        )
                      ])
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "success",
                            disabled: _vm.loadingImport,
                            type: "submit",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.importChainFromAnotherProjects(
                                _vm.anotherProjectid,
                                _vm.anotherAfe
                              )
                            }
                          }
                        },
                        [
                          _vm._v("Import\n            "),
                          _c("i", { staticClass: "fa fa-file-import" })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-row", [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-progress",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.progressBar,
                            expression: "progressBar"
                          }
                        ],
                        staticClass: "mt-2",
                        staticStyle: { "font-size": "0.8rem" },
                        attrs: {
                          max: _vm.progressBarServicesCount,
                          height: "2rem",
                          "show-value": ""
                        }
                      },
                      [
                        _vm.loadingCount == _vm.progressBarServicesCount
                          ? _c("b-progress-bar", {
                              attrs: {
                                value: _vm.loadingCount,
                                label:
                                  _vm.loadingCount +
                                  "/" +
                                  _vm.progressBarServicesCount +
                                  " (100%)",
                                variant: "success"
                              }
                            })
                          : _c("b-progress-bar", {
                              attrs: {
                                value: _vm.loadingCount,
                                label:
                                  _vm.loadingCount +
                                  "/" +
                                  _vm.progressBarServicesCount +
                                  " (" +
                                  (
                                    (_vm.loadingCount /
                                      _vm.progressBarServicesCount) *
                                    100
                                  ).toFixed(2) +
                                  "%" +
                                  ")",
                                variant: "success"
                              }
                            })
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.progressBar,
                            expression: "progressBar"
                          }
                        ],
                        staticClass: "mt-3"
                      },
                      [
                        _vm._v(
                          "Please do not close this window until 100% or it may be unsuccessful.\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "exportChainToAnotherProjectModal",
              attrs: {
                id: "exportChainToAnotherProjectModal",
                title: "Export To Another Project",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                hidden: function($event) {
                  _vm.loadCostCodeChain(), (_vm.progressBar = false)
                },
                shown: function($event) {
                  ;(_vm.loadingCount = 0),
                    (_vm.progressBarServicesCount = 0),
                    (_vm.progressBar = false)
                }
              }
            },
            [
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("h5", [_vm._v("Select Project")]),
                      _c("multiselect", {
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "Search For Project",
                          "tag-placeholder": "Search For Project",
                          "track-by": "projectid",
                          options: _vm.projectData,
                          multiple: false,
                          "close-on-select": true,
                          "clear-on-select": true,
                          "preserve-search": true,
                          taggable: true,
                          label: "projectname",
                          "preselect-first": true
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadAfesFromAnother(
                              _vm.anotherProjectid.projectid
                            )
                          }
                        },
                        model: {
                          value: _vm.anotherProjectid,
                          callback: function($$v) {
                            _vm.anotherProjectid = $$v
                          },
                          expression: "anotherProjectid"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { md: "12" } },
                    [
                      !_vm.$route.meta.is_resource
                        ? _c("h5", [_vm._v("Select AFE")])
                        : _c("h5", [_vm._v("Select Cost Center")]),
                      _c("multiselect", {
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "Search For Project",
                          "tag-placeholder": "Search For AFE",
                          "track-by": "pwcaid",
                          options: _vm.anotherAfeData,
                          multiple: false,
                          "close-on-select": true,
                          "clear-on-select": true,
                          "preserve-search": true,
                          taggable: true,
                          label: "afenum",
                          "preselect-first": true
                        },
                        model: {
                          value: _vm.anotherAfe,
                          callback: function($$v) {
                            _vm.anotherAfe = $$v
                          },
                          expression: "anotherAfe"
                        }
                      })
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "mt-3", attrs: { md: "12" } }, [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          "**Please import services before importing to another project."
                        )
                      ])
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "success",
                            disabled: _vm.loadingImport,
                            type: "submit",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.exportCCChainToAnotherProject(
                                _vm.anotherProjectid,
                                _vm.anotherAfe
                              )
                            }
                          }
                        },
                        [
                          _vm._v("Export\n            Cost Codes "),
                          _c("i", { staticClass: "fa fa-file-import" })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-row", [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-progress",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.progressBar,
                            expression: "progressBar"
                          }
                        ],
                        staticClass: "mt-2",
                        staticStyle: { "font-size": "0.8rem" },
                        attrs: {
                          max: _vm.progressBarServicesCount,
                          height: "2rem",
                          "show-value": ""
                        }
                      },
                      [
                        _vm.loadingCount == _vm.progressBarServicesCount
                          ? _c("b-progress-bar", {
                              attrs: {
                                value: _vm.loadingCount,
                                label:
                                  _vm.loadingCount +
                                  "/" +
                                  _vm.progressBarServicesCount +
                                  " (100%)",
                                variant: "success"
                              }
                            })
                          : _c("b-progress-bar", {
                              attrs: {
                                value: _vm.loadingCount,
                                label:
                                  _vm.loadingCount +
                                  "/" +
                                  _vm.progressBarServicesCount +
                                  " (" +
                                  (
                                    (_vm.loadingCount /
                                      _vm.progressBarServicesCount) *
                                    100
                                  ).toFixed(2) +
                                  "%" +
                                  ")",
                                variant: "success"
                              }
                            })
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.progressBar,
                            expression: "progressBar"
                          }
                        ],
                        staticClass: "mt-3"
                      },
                      [
                        _vm._v(
                          "Please do not close this window until 100% or it may be unsuccessful.\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "editWSS",
              attrs: {
                id: "editWSS",
                title: "Edit WSS",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                shown: function($event) {
                  return _vm.clearErrors()
                }
              }
            },
            [
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-2",
                      attrs: { value: "0", "unchecked-value": "1" },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _c("p", { staticClass: "ml-3 mb-0" }, [
                        _vm.status == 0
                          ? _c("span", [_vm._v("Active")])
                          : _vm._e(),
                        _vm.status != 0
                          ? _c("span", [_vm._v(" Inactive")])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.error,
                    expression: "error"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle mt-3"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.error,
                      expression: "error"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("Error\n        editing WSS.")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" },
                  on: { click: _vm.changeWssStatus }
                },
                [_vm._v("Edit")]
              )
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "editCC",
              attrs: {
                id: "editCC",
                title: "Edit Cost Code",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                shown: function($event) {
                  return _vm.clearEditCCErrors()
                }
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "pb-2",
                  attrs: { "data-vv-scope": "editcc" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.editCCValidater("editcc")
                    }
                  }
                },
                [
                  _c("p", [
                    _vm._v(
                      "**Changing this cost code will update all matching existing cost codes"
                    )
                  ]),
                  _c("label", { attrs: { for: "" } }, [_vm._v("Code")]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c("b-input-group-text", [
                            _c("i", { staticClass: "fa fa-hashtag" })
                          ])
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "code",
                          placeholder: "Code"
                        },
                        model: {
                          value: _vm.code,
                          callback: function($$v) {
                            _vm.code = $$v
                          },
                          expression: "code"
                        }
                      })
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("editcc.code"),
                        expression: "errors.has('editcc.code')"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("editcc.code"),
                          expression: "errors.has('editcc.code')"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [_vm._v("The code is required.")]
                  ),
                  _c(
                    "b-input-group",
                    { staticClass: "mt-3 mb-2" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "ml-2 mt-1",
                          attrs: { value: "0", "unchecked-value": "1" },
                          model: {
                            value: _vm.status,
                            callback: function($$v) {
                              _vm.status = $$v
                            },
                            expression: "status"
                          }
                        },
                        [
                          _c("p", { staticClass: "ml-3 mb-0" }, [
                            _vm.status == 0
                              ? _c("span", [_vm._v("Active")])
                              : _vm._e(),
                            _vm.status != 0
                              ? _c("span", [_vm._v(" Inactive")])
                              : _vm._e()
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle mt-3"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [
                      _vm._v(
                        "Cost\n          Code Edit Error. Cannot have duplicates. Please contact admin."
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4",
                      attrs: { variant: "success", type: "submit", block: "" }
                    },
                    [_vm._v("Edit")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "addCat",
              attrs: {
                id: "addCat",
                title: "Add Category",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                shown: function($event) {
                  return _vm.clearErrors()
                }
              }
            },
            [
              _c(
                "form",
                {
                  attrs: { "data-vv-scope": "addCat" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.addCat("addCat")
                    }
                  }
                },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Category Name")
                  ]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c("b-input-group-text", [
                            _c("i", { staticClass: "fa fa-folder" })
                          ])
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "category",
                          placeholder: "Category"
                        },
                        model: {
                          value: _vm.category,
                          callback: function($$v) {
                            _vm.category = $$v
                          },
                          expression: "category"
                        }
                      })
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("addCat.category"),
                        expression: "errors.has('addCat.category')"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("addCat.category"),
                          expression: "errors.has('addCat.category')"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [_vm._v("The category name is required.")]
                  ),
                  _c("label", { staticClass: "mt-3", attrs: { for: "" } }, [
                    _vm._v("Category Description")
                  ]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c("b-input-group-text", [
                            _c("i", { staticClass: "fa fa-folder" })
                          ])
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "description",
                          placeholder: "Description #"
                        },
                        model: {
                          value: _vm.description,
                          callback: function($$v) {
                            _vm.description = $$v
                          },
                          expression: "description"
                        }
                      })
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("addCat.description"),
                        expression: "errors.has('addCat.description')"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("addCat.description"),
                          expression: "errors.has('addCat.description')"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [_vm._v("The description is required.")]
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "mb-3 help is-danger"
                    },
                    [
                      _vm._v(
                        "Error. Cannot have duplicate categories. Please contact admin if problems\n          persist."
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4",
                      attrs: { variant: "success", type: "submit", block: "" }
                    },
                    [_vm._v("Create Category")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "editCat",
              attrs: {
                id: "editCat",
                title: "Edit Category",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                shown: function($event) {
                  _vm.error = false
                  _vm.$validator.reset()
                }
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "pb-2",
                  attrs: { "data-vv-scope": "editcat" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.editCatValidator("editcat")
                    }
                  }
                },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Category Name")
                  ]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c("b-input-group-text", [
                            _c("i", { staticClass: "fa fa-folder" })
                          ])
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "category",
                          placeholder: "Category Name"
                        },
                        model: {
                          value: _vm.category,
                          callback: function($$v) {
                            _vm.category = $$v
                          },
                          expression: "category"
                        }
                      })
                    ],
                    1
                  ),
                  _c("label", { attrs: { for: "" } }, [_vm._v("Description")]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c("b-input-group-text", [
                            _c("i", { staticClass: "fa fa-folder" })
                          ])
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "description",
                          placeholder: "Category Description"
                        },
                        model: {
                          value: _vm.description,
                          callback: function($$v) {
                            _vm.description = $$v
                          },
                          expression: "description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-input-group",
                    { staticClass: "mt-3 mb-2" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "ml-2 mt-1",
                          attrs: { value: "0", "unchecked-value": "1" },
                          model: {
                            value: _vm.status,
                            callback: function($$v) {
                              _vm.status = $$v
                            },
                            expression: "status"
                          }
                        },
                        [
                          _c("p", { staticClass: "ml-3 mb-0" }, [
                            _vm.status == 0
                              ? _c("span", [_vm._v("Active")])
                              : _vm._e(),
                            _vm.status != 0
                              ? _c("span", [_vm._v(" Inactive")])
                              : _vm._e()
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle mt-3"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [
                      _vm._v(
                        "Category Edit Error. Cannot have duplicates. Please contact admin."
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4",
                      attrs: { variant: "success", type: "submit", block: "" }
                    },
                    [_vm._v("Edit")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "addWell",
              attrs: {
                id: "addWell",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                shown: function($event) {
                  return _vm.clearErrors()
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "modal-title",
                  fn: function() {
                    return [
                      _c("h2", [_vm._v("ADD Well")]),
                      _c(
                        "b-button",
                        {
                          staticClass: "switch_asset",
                          style: {
                            "background-color": !_vm.switch_asset
                              ? "#5A6268"
                              : "#4DBD74"
                          },
                          attrs: { size: "sm" },
                          on: {
                            click: function($event) {
                              _vm.switch_asset = true
                            }
                          }
                        },
                        [_vm._v("Other Asset")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "switch_asset",
                          style: {
                            "background-color": !_vm.switch_asset
                              ? "#4DBD74"
                              : "#5A6268"
                          },
                          attrs: { size: "sm" },
                          on: {
                            click: function($event) {
                              _vm.switch_asset = false
                            }
                          }
                        },
                        [_vm._v("Well Asset")]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "form",
                {
                  attrs: { "data-vv-scope": "well" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.wellValidator("well")
                    }
                  }
                },
                [
                  _c("toggle-button", {
                    staticClass: "float-right",
                    attrs: {
                      height: 20,
                      width: 80,
                      value: false,
                      sync: true,
                      speed: 480,
                      color: { checked: "#4dbd74", unchecked: "#fd8600" },
                      labels: { checked: "NTS", unchecked: "DLS" }
                    },
                    on: {
                      change: function($event) {
                        return _vm.clearWellFields()
                      }
                    },
                    model: {
                      value: _vm.wellChange,
                      callback: function($$v) {
                        _vm.wellChange = $$v
                      },
                      expression: "wellChange"
                    }
                  }),
                  !_vm.switch_asset
                    ? _c("label", {}, [
                        _vm._v("Current "),
                        !_vm.$route.meta.is_resource
                          ? _c("span", [_vm._v("UWI")])
                          : _c("span", [_vm._v("Operation")])
                      ])
                    : _c("label", {}, [_vm._v("Add Asset Description")]),
                  !_vm.wellChange && !_vm.switch_asset
                    ? _c("h5", [
                        _vm._v(
                          "1" +
                            _vm._s(_vm.locEx) +
                            "/" +
                            _vm._s(_vm.lsd) +
                            "-" +
                            _vm._s(_vm.sec) +
                            "-" +
                            _vm._s(_vm.twp) +
                            "-" +
                            _vm._s(_vm.rge) +
                            "W" +
                            _vm._s(_vm.meridian) +
                            "/0" +
                            _vm._s(_vm.seq)
                        )
                      ])
                    : _vm._e(),
                  _vm.wellChange && !_vm.switch_asset
                    ? _c("h5", [
                        _vm._v(
                          "20" +
                            _vm._s(_vm.locEx) +
                            "/" +
                            _vm._s(_vm.qtr) +
                            "-" +
                            _vm._s(_vm.unit) +
                            "-" +
                            _vm._s(_vm.blk) +
                            "-" +
                            _vm._s(_vm.map) +
                            "-" +
                            _vm._s(_vm.msd) +
                            "-" +
                            _vm._s(_vm.sht) +
                            "/0" +
                            _vm._s(_vm.seq) +
                            "\n        "
                        )
                      ])
                    : _vm._e(),
                  !_vm.switch_asset ? _c("hr") : _vm._e(),
                  !_vm.switch_asset
                    ? _c("label", {}, [
                        !_vm.$route.meta.is_resource
                          ? _c("span", [_vm._v("Legal Bottom Location")])
                          : _c("span", [_vm._v("Operation Location")])
                      ])
                    : _vm._e(),
                  !_vm.wellChange && !_vm.switch_asset
                    ? _c(
                        "div",
                        { staticClass: "uwiSeperators" },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "fa fa-location" })
                                  ])
                                ],
                                1
                              ),
                              _vm._v("\n            1\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "locEx",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "LocEx",
                                  placeholder: "LocEx"
                                },
                                model: {
                                  value: _vm.locEx,
                                  callback: function($$v) {
                                    _vm.locEx = $$v
                                  },
                                  expression: "locEx"
                                }
                              }),
                              _vm._v("/\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "lsd",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "LSD",
                                  placeholder: "LSD"
                                },
                                model: {
                                  value: _vm.lsd,
                                  callback: function($$v) {
                                    _vm.lsd = $$v
                                  },
                                  expression: "lsd"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "sec",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "sec",
                                  placeholder: "Section"
                                },
                                model: {
                                  value: _vm.sec,
                                  callback: function($$v) {
                                    _vm.sec = $$v
                                  },
                                  expression: "sec"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "twp",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "twp",
                                  placeholder: "Township"
                                },
                                model: {
                                  value: _vm.twp,
                                  callback: function($$v) {
                                    _vm.twp = $$v
                                  },
                                  expression: "twp"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "rge",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "rge",
                                  placeholder: "Range"
                                },
                                model: {
                                  value: _vm.rge,
                                  callback: function($$v) {
                                    _vm.rge = $$v
                                  },
                                  expression: "rge"
                                }
                              }),
                              _vm._v("W\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "meridian",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "meridian",
                                  placeholder: "Meridian"
                                },
                                model: {
                                  value: _vm.meridian,
                                  callback: function($$v) {
                                    _vm.meridian = $$v
                                  },
                                  expression: "meridian"
                                }
                              }),
                              _vm._v("/0\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "seq",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "seq",
                                  placeholder: "Seq"
                                },
                                model: {
                                  value: _vm.seq,
                                  callback: function($$v) {
                                    _vm.seq = $$v
                                  },
                                  expression: "seq"
                                }
                              })
                            ],
                            1
                          ),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.locEx"),
                                  expression: "errors.has('well.locEx')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.locEx"),
                                    expression: "errors.has('well.locEx')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The LocEx is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.lsd"),
                                  expression: "errors.has('well.lsd')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.lsd"),
                                    expression: "errors.has('well.lsd')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The lsd is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.sec"),
                                  expression: "errors.has('well.sec')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.sec"),
                                    expression: "errors.has('well.sec')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The sec is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.twp"),
                                  expression: "errors.has('well.twp')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.twp"),
                                    expression: "errors.has('well.twp')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The twp is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.rge"),
                                  expression: "errors.has('well.rge')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.rge"),
                                    expression: "errors.has('well.rge')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The rge is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.meridian"),
                                  expression: "errors.has('well.meridian')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.meridian"),
                                    expression: "errors.has('well.meridian')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The meridian is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.seq"),
                                  expression: "errors.has('well.seq')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.seq"),
                                    expression: "errors.has('well.seq')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The seq is required.")]
                            )
                          ]),
                          _c("hr")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.wellChange && !_vm.switch_asset
                    ? _c(
                        "div",
                        { staticClass: "uwiSeperators" },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "fa fa-location" })
                                  ])
                                ],
                                1
                              ),
                              _vm._v("\n            20\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "LocEx",
                                  placeholder: "LocEx"
                                },
                                model: {
                                  value: _vm.locEx,
                                  callback: function($$v) {
                                    _vm.locEx = $$v
                                  },
                                  expression: "locEx"
                                }
                              }),
                              _vm._v("/\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "qtr",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "qtr",
                                  placeholder: "Qtr"
                                },
                                model: {
                                  value: _vm.qtr,
                                  callback: function($$v) {
                                    _vm.qtr = $$v
                                  },
                                  expression: "qtr"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "unit",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "unit",
                                  placeholder: "Unit"
                                },
                                model: {
                                  value: _vm.unit,
                                  callback: function($$v) {
                                    _vm.unit = $$v
                                  },
                                  expression: "unit"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "blk",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "blk",
                                  placeholder: "Blk"
                                },
                                model: {
                                  value: _vm.blk,
                                  callback: function($$v) {
                                    _vm.blk = $$v
                                  },
                                  expression: "blk"
                                }
                              }),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "map",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "map",
                                  placeholder: "Map"
                                },
                                model: {
                                  value: _vm.map,
                                  callback: function($$v) {
                                    _vm.map = $$v
                                  },
                                  expression: "map"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "msd",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "MSD",
                                  placeholder: "MSD"
                                },
                                model: {
                                  value: _vm.msd,
                                  callback: function($$v) {
                                    _vm.msd = $$v
                                  },
                                  expression: "msd"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "sht",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "sht",
                                  placeholder: "Sht"
                                },
                                model: {
                                  value: _vm.sht,
                                  callback: function($$v) {
                                    _vm.sht = $$v
                                  },
                                  expression: "sht"
                                }
                              }),
                              _vm._v("/0\n            "),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "seq",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "seq",
                                  placeholder: "Seq"
                                },
                                model: {
                                  value: _vm.seq,
                                  callback: function($$v) {
                                    _vm.seq = $$v
                                  },
                                  expression: "seq"
                                }
                              })
                            ],
                            1
                          ),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.locEx"),
                                  expression: "errors.has('well.locEx')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.locEx"),
                                    expression: "errors.has('well.locEx')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The LocEx is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.qtr"),
                                  expression: "errors.has('well.qtr')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.qtr"),
                                    expression: "errors.has('well.qtr')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The Qtr is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.blk"),
                                  expression: "errors.has('well.blk')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.blk"),
                                    expression: "errors.has('well.blk')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The Blk is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.map"),
                                  expression: "errors.has('well.map')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.map"),
                                    expression: "errors.has('well.map')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The Map is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.msd"),
                                  expression: "errors.has('well.msd')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.msd"),
                                    expression: "errors.has('well.msd')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The MSD is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.sht"),
                                  expression: "errors.has('well.sht')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.sht"),
                                    expression: "errors.has('well.sht')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The Sht is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("well.seq"),
                                  expression: "errors.has('well.seq')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("well.seq"),
                                    expression: "errors.has('well.seq')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The seq is required.")]
                            )
                          ]),
                          _c("hr")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.switch_asset
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.assetDescription,
                            expression: "assetDescription"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "Enter Asset Description"
                        },
                        domProps: { value: _vm.assetDescription },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.assetDescription = $event.target.value
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.switch_asset ? _c("hr") : _vm._e(),
                  _c("label", {}, [
                    !_vm.$route.meta.is_resource
                      ? _c("span", [_vm._v("Legal Surface Location")])
                      : _c("span", [
                          _vm._v("Operation\n            Bottom Location")
                        ])
                  ]),
                  !_vm.wellChange
                    ? _c(
                        "div",
                        { staticClass: "uwiSeperators" },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "fa fa-location" })
                                  ])
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                key: "lsdBottom",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "lsdBottom",
                                  placeholder: "LSD"
                                },
                                model: {
                                  value: _vm.lsdBottom,
                                  callback: function($$v) {
                                    _vm.lsdBottom = $$v
                                  },
                                  expression: "lsdBottom"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                key: "secBottom",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "secBottom",
                                  placeholder: "Section"
                                },
                                model: {
                                  value: _vm.secBottom,
                                  callback: function($$v) {
                                    _vm.secBottom = $$v
                                  },
                                  expression: "secBottom"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                key: "twpBottom",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "twpBottom",
                                  placeholder: "Township"
                                },
                                model: {
                                  value: _vm.twpBottom,
                                  callback: function($$v) {
                                    _vm.twpBottom = $$v
                                  },
                                  expression: "twpBottom"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                key: "rgeBottom",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "rgeBottom",
                                  placeholder: "Range"
                                },
                                model: {
                                  value: _vm.rgeBottom,
                                  callback: function($$v) {
                                    _vm.rgeBottom = $$v
                                  },
                                  expression: "rgeBottom"
                                }
                              }),
                              _vm._v("W\n            "),
                              _c("b-form-input", {
                                key: "meridianBottom",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "meridianBottom",
                                  placeholder: "Meridian"
                                },
                                model: {
                                  value: _vm.meridianBottom,
                                  callback: function($$v) {
                                    _vm.meridianBottom = $$v
                                  },
                                  expression: "meridianBottom"
                                }
                              })
                            ],
                            1
                          ),
                          _c("hr")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.wellChange
                    ? _c(
                        "div",
                        { staticClass: "uwiSeperators" },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "fa fa-location" })
                                  ])
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                key: "qtrBottom",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "qtr",
                                  placeholder: "Qtr"
                                },
                                model: {
                                  value: _vm.qtrBottom,
                                  callback: function($$v) {
                                    _vm.qtrBottom = $$v
                                  },
                                  expression: "qtrBottom"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                key: "unitBottom",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "unit",
                                  placeholder: "Unit"
                                },
                                model: {
                                  value: _vm.unitBottom,
                                  callback: function($$v) {
                                    _vm.unitBottom = $$v
                                  },
                                  expression: "unitBottom"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                key: "blkBottom",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "blk",
                                  placeholder: "Blk"
                                },
                                model: {
                                  value: _vm.blkBottom,
                                  callback: function($$v) {
                                    _vm.blkBottom = $$v
                                  },
                                  expression: "blkBottom"
                                }
                              }),
                              _c("b-form-input", {
                                key: "mapBottom",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "map",
                                  placeholder: "Map"
                                },
                                model: {
                                  value: _vm.mapBottom,
                                  callback: function($$v) {
                                    _vm.mapBottom = $$v
                                  },
                                  expression: "mapBottom"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                key: "msdBottom",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "MSD",
                                  placeholder: "MSD"
                                },
                                model: {
                                  value: _vm.msdBottom,
                                  callback: function($$v) {
                                    _vm.msdBottom = $$v
                                  },
                                  expression: "msdBottom"
                                }
                              }),
                              _vm._v("-\n            "),
                              _c("b-form-input", {
                                key: "shtBottom",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "sht",
                                  placeholder: "Sht"
                                },
                                model: {
                                  value: _vm.shtBottom,
                                  callback: function($$v) {
                                    _vm.shtBottom = $$v
                                  },
                                  expression: "shtBottom"
                                }
                              })
                            ],
                            1
                          ),
                          _c("hr")
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.switch_asset
                    ? _c("label", {}, [
                        !_vm.$route.meta.is_resource
                          ? _c("span", [_vm._v("License Number")])
                          : _c("span", [_vm._v("Operation License Number")])
                      ])
                    : _vm._e(),
                  !_vm.switch_asset
                    ? _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-hashtag" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "ln",
                              placeholder: "License Number"
                            },
                            model: {
                              value: _vm.ln,
                              callback: function($$v) {
                                _vm.ln = $$v
                              },
                              expression: "ln"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("well.ln"),
                        expression: "errors.has('well.ln')"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("well.ln"),
                          expression: "errors.has('well.ln')"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [_vm._v("The ln is required.")]
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error"
                      }
                    ],
                    staticClass: "fa mt-2 fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "mb-3 help is-danger"
                    },
                    [
                      _vm._v(
                        "Error. Cannot have duplicate well numbers. Please contact admin if problems\n          persist."
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        variant: "success",
                        disabled:
                          _vm.assetDescription.trim().length === 0 &&
                          _vm.switch_asset,
                        type: "submit",
                        block: ""
                      }
                    },
                    [_vm._v("Create")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "editWell",
              attrs: {
                id: "editWell",
                title: "Edit",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                shown: function($event) {
                  return _vm.clearErrors()
                }
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "pb-2",
                  attrs: { "data-vv-scope": "editwell" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.editWellValidate("editwell")
                    }
                  }
                },
                [
                  _c("strong", [
                    _vm._v("Last "),
                    !_vm.$route.meta.is_resource
                      ? _c("span", [_vm._v("UWI")])
                      : _c("span", [_vm._v("Operation")]),
                    _vm._v(" is the\n          current")
                  ]),
                  _c("label", {}, [
                    !_vm.$route.meta.is_resource
                      ? _c("span", [_vm._v("UWI")])
                      : _c("span", [_vm._v("Operation")]),
                    _vm._v(" #1")
                  ]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c("b-input-group-text", [
                            _c("i", { staticClass: "fa fa-hashtag" })
                          ])
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          readonly: "",
                          name: "uwi",
                          placeholder: "#1"
                        },
                        model: {
                          value: _vm.uwi,
                          callback: function($$v) {
                            _vm.uwi = $$v
                          },
                          expression: "uwi"
                        }
                      })
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("editwell.uwi"),
                        expression: "errors.has('editwell.uwi')"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("editwell.uwi"),
                          expression: "errors.has('editwell.uwi')"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [_vm._v("The well license is required.")]
                  ),
                  _c("label", {}, [
                    !_vm.$route.meta.is_resource
                      ? _c("span", [_vm._v("UWI")])
                      : _c("span", [_vm._v("Operation")]),
                    _vm._v(" #2 (If it\n          changes)")
                  ]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c("b-input-group-text", [
                            _c("i", { staticClass: "fa fa-hashtag" })
                          ])
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "uwi2",
                          placeholder: "#2"
                        },
                        model: {
                          value: _vm.uwi2,
                          callback: function($$v) {
                            _vm.uwi2 = $$v
                          },
                          expression: "uwi2"
                        }
                      })
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("editwell.uwi2"),
                        expression: "errors.has('editwell.uwi2')"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("editwell.uwi2"),
                          expression: "errors.has('editwell.uwi2')"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [_vm._v("The well license is required.")]
                  ),
                  _c("label", {}, [
                    !_vm.$route.meta.is_resource
                      ? _c("span", [_vm._v("UWI")])
                      : _c("span", [_vm._v("Operation")]),
                    _vm._v(" #3 (If it\n          changes)")
                  ]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c("b-input-group-text", [
                            _c("i", { staticClass: "fa fa-hashtag" })
                          ])
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "uwi3",
                          placeholder: "#3"
                        },
                        model: {
                          value: _vm.uwi3,
                          callback: function($$v) {
                            _vm.uwi3 = $$v
                          },
                          expression: "uwi3"
                        }
                      })
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("editwell.uwi3"),
                        expression: "errors.has('editwell.uwi3')"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("editwell.uwi3"),
                          expression: "errors.has('editwell.uwi3')"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [_vm._v("The well license is required.")]
                  ),
                  _c("label", {}, [_vm._v("Surface Location")]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c("b-input-group-text", [
                            _c("i", { staticClass: "fa fa-location" })
                          ])
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "surfaceLocation",
                          placeholder: "Surface Location"
                        },
                        model: {
                          value: _vm.surfaceLocation,
                          callback: function($$v) {
                            _vm.surfaceLocation = $$v
                          },
                          expression: "surfaceLocation"
                        }
                      })
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("editwell.surfaceLocation"),
                        expression: "errors.has('editwell.surfaceLocation')"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("editwell.surfaceLocation"),
                          expression: "errors.has('editwell.surfaceLocation')"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [_vm._v("The ln is required.")]
                  ),
                  _c("label", {}, [
                    !_vm.$route.meta.is_resource
                      ? _c("span", [_vm._v("License Number")])
                      : _c("span", [
                          _vm._v("Operation License\n            Number")
                        ])
                  ]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c("b-input-group-text", [
                            _c("i", { staticClass: "fa fa-hashtag" })
                          ])
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "ln",
                          placeholder: "License Number"
                        },
                        model: {
                          value: _vm.ln,
                          callback: function($$v) {
                            _vm.ln = $$v
                          },
                          expression: "ln"
                        }
                      })
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("editwell.ln"),
                        expression: "errors.has('editwell.ln')"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("editwell.ln"),
                          expression: "errors.has('editwell.ln')"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [_vm._v("The ln is required.")]
                  ),
                  _c("hr"),
                  _c(
                    "b-input-group",
                    { staticClass: "mt-3 mb-2" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "ml-2 mt-1",
                          attrs: { value: "0", "unchecked-value": "1" },
                          model: {
                            value: _vm.status,
                            callback: function($$v) {
                              _vm.status = $$v
                            },
                            expression: "status"
                          }
                        },
                        [
                          _c("p", { staticClass: "ml-3 mb-0" }, [
                            _vm.status == 0
                              ? _c("span", [_vm._v("Active")])
                              : _vm._e(),
                            _vm.status != 0
                              ? _c("span", [_vm._v(" Inactive")])
                              : _vm._e()
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle mt-3"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [
                      _vm._v(
                        "Edit\n          Error. Cannot have duplicate wells."
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4",
                      attrs: { variant: "success", type: "submit", block: "" }
                    },
                    [
                      !_vm.$route.meta.is_resource
                        ? _c("span", [_vm._v("Edit\n            Well")])
                        : _c("span", [_vm._v("Edit Operation")])
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "addAfeNum",
              attrs: {
                id: "addAfeNum",
                title: "Add",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                shown: function($event) {
                  _vm.clearErrors(), _vm.loadCostCenters()
                },
                hidden: function($event) {
                  _vm.costCenters = []
                }
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "mb-3",
                  attrs: { "data-vv-scope": "afe" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.addAfeValidator("afe")
                    }
                  }
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c("label", [
                            !_vm.$route.meta.is_resource
                              ? _c("span", [_vm._v("Well")])
                              : _c("span", [_vm._v("Operation Name")])
                          ]),
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                { staticStyle: { "z-index": "99" } },
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "fa fa-tint" })
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|min_value:1",
                                      expression: "'required|min_value:1'"
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.pwid,
                                      expression: "pwid"
                                    }
                                  ],
                                  staticClass: "select-account form-control",
                                  attrs: { name: "pwid" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.pwid = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "0",
                                        disabled: "",
                                        selected: ""
                                      }
                                    },
                                    [_vm._v("Select")]
                                  ),
                                  _vm._l(_vm.wtsWellData, function(option) {
                                    return _c(
                                      "option",
                                      {
                                        attrs: { for: "" },
                                        domProps: { value: option.pwid }
                                      },
                                      [_vm._v(_vm._s(option.uwi))]
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("afe.pwid"),
                                expression: "errors.has('afe.pwid')"
                              }
                            ],
                            staticClass: "fa fa-exclamation-triangle"
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("afe.pwid"),
                                  expression: "errors.has('afe.pwid')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [_vm._v("The well is required.")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c("label", [_vm._v("Project Category")]),
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                { staticStyle: { "z-index": "99" } },
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "fa fa-cog" })
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|min_value:1",
                                      expression: "'required|min_value:1'"
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.pcid,
                                      expression: "pcid"
                                    }
                                  ],
                                  staticClass: "select-account form-control",
                                  attrs: { name: "pcid" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.pcid = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "0",
                                        disabled: "",
                                        selected: ""
                                      }
                                    },
                                    [_vm._v("Select Category")]
                                  ),
                                  _vm._l(_vm.wtsCatData, function(option) {
                                    return _c(
                                      "option",
                                      {
                                        attrs: { for: "" },
                                        domProps: { value: option.pcid }
                                      },
                                      [_vm._v(_vm._s(option.category))]
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("afe.pcid"),
                                expression: "errors.has('afe.pcid')"
                              }
                            ],
                            staticClass: "fa fa-exclamation-triangle"
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("afe.pcid"),
                                  expression: "errors.has('afe.pcid')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [_vm._v("The project is required.")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c("label", [
                            !_vm.$route.meta.is_resource
                              ? _c("span", [_vm._v("AFE Number")])
                              : _c("span", [_vm._v("Cost Center")])
                          ]),
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "fa fa-hashtag" })
                                  ])
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "afenum",
                                  placeholder: "#"
                                },
                                model: {
                                  value: _vm.addnewafenum,
                                  callback: function($$v) {
                                    _vm.addnewafenum = $$v
                                  },
                                  expression: "addnewafenum"
                                }
                              })
                            ],
                            1
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("afe.afenum"),
                                expression: "errors.has('afe.afenum')"
                              }
                            ],
                            staticClass: "fa fa-exclamation-triangle"
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("afe.afenum"),
                                  expression: "errors.has('afe.afenum')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [_vm._v("The afe number is required.")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "mb-3 help is-danger"
                    },
                    [
                      _vm._v(
                        "Error. Cannot have duplicates. Please contact admin if problems persist."
                      )
                    ]
                  ),
                  _c("br"),
                  _c("h5", [_vm._v("Select Cost Center")]),
                  _c("multiselect", {
                    staticClass: "md-4",
                    staticStyle: { "z-index": "100" },
                    attrs: {
                      placeholder: "Search for Cost Center",
                      "hide-selected": true,
                      "tag-placeholder": "Search for Cost Center",
                      "track-by": "name",
                      options: _vm.costCenters,
                      multiple: true,
                      "close-on-select": true,
                      "clear-on-select": false,
                      "preserve-search": true,
                      taggable: false,
                      label: "name",
                      "preselect-first": false
                    },
                    model: {
                      value: _vm.selectedCostCenter,
                      callback: function($$v) {
                        _vm.selectedCostCenter = $$v
                      },
                      expression: "selectedCostCenter"
                    }
                  }),
                  _c("br"),
                  _vm.selectedCostCenter.length > 0
                    ? _c(
                        "table",
                        { staticClass: "table table-striped table-hover" },
                        [
                          _c("thead", { staticClass: "thead-dark" }, [
                            _c("tr", [
                              _c("th", [_vm._v("Cost Center")]),
                              _c("th", [_vm._v(" Cost Center Name")]),
                              _c("th", [_vm._v("Cost Center Percentage")])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.selectedCostCenter, function(center) {
                              return _c("tr", [
                                _c("td", [
                                  _c("h5", [_vm._v(_vm._s(center.name))])
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(center.cost_center_name))
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c("b-input-group-text", [
                                              _c("i", {
                                                staticClass: "fa fa-percentage"
                                              })
                                            ])
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          attrs: { type: "text" },
                                          model: {
                                            value: center.percentage,
                                            callback: function($$v) {
                                              _vm.$set(
                                                center,
                                                "percentage",
                                                $$v
                                              )
                                            },
                                            expression: "center.percentage"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("hr"),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "mt-3 float-right",
                          attrs: { md: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-2 mb-2",
                              attrs: {
                                variant: "success",
                                type: "submit",
                                block: ""
                              }
                            },
                            [
                              _vm._v("Add New "),
                              _c("i", { staticClass: "fa fa-plus" })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "editAfeNum",
              attrs: {
                id: "editAfeNum",
                title: "Edit",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                shown: function($event) {
                  return _vm.loadCostCenters()
                },
                hidden: function($event) {
                  ;(_vm.costCenters = []), (_vm.editafenum = "")
                }
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "pb-2",
                  attrs: { "data-vv-scope": "editafe" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.editAfeValidate("editafe")
                    }
                  }
                },
                [
                  _c("label", {}, [
                    !_vm.$route.meta.is_resource
                      ? _c("span", [_vm._v(" AFE Number")])
                      : _c("span", [_vm._v(" Cost Center")])
                  ]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c("b-input-group-text", [
                            _c("i", { staticClass: "fa fa-user" })
                          ])
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "editafenum",
                          placeholder: "AFE #"
                        },
                        model: {
                          value: _vm.editafenum,
                          callback: function($$v) {
                            _vm.editafenum = $$v
                          },
                          expression: "editafenum"
                        }
                      })
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("editafe.editafenum"),
                        expression: "errors.has('editafe.editafenum')"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("editafe.editafenum"),
                          expression: "errors.has('editafe.editafenum')"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [_vm._v("The AFE is required.")]
                  ),
                  _c("br"),
                  _c("h5", [_vm._v("Select Cost Center")]),
                  _c("multiselect", {
                    attrs: {
                      placeholder: "Search for Cost Center",
                      "hide-selected": true,
                      "tag-placeholder": "Search for Cost Center",
                      "track-by": "name",
                      options: _vm.cost_Centers,
                      multiple: true,
                      "close-on-select": true,
                      "clear-on-select": false,
                      "preserve-search": true,
                      taggable: false,
                      label: "name",
                      "preselect-first": false
                    },
                    on: { remove: _vm.toggleUnSelectMarket },
                    model: {
                      value: _vm.cc_percentage,
                      callback: function($$v) {
                        _vm.cc_percentage = $$v
                      },
                      expression: "cc_percentage"
                    }
                  }),
                  _c("br"),
                  _vm.cc_percentage.length > 0
                    ? _c(
                        "table",
                        { staticClass: "table table-striped table-hover" },
                        [
                          _c("thead", { staticClass: "thead-dark" }, [
                            _c("tr", [
                              _c("th", [_vm._v("Cost Center")]),
                              _c("th", [_vm._v(" Cost Center Name")]),
                              _c("th", [_vm._v("Cost Center Percentage")])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.cc_percentage, function(center) {
                              return _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(center.name) +
                                      "\n              "
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(center.cost_center_name))
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c("b-input-group-text", [
                                              _c("i", {
                                                staticClass: "fa fa-percentage"
                                              })
                                            ])
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          attrs: { type: "text" },
                                          model: {
                                            value: center.percentage,
                                            callback: function($$v) {
                                              _vm.$set(
                                                center,
                                                "percentage",
                                                $$v
                                              )
                                            },
                                            expression: "center.percentage"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("hr"),
                  _c(
                    "b-input-group",
                    { staticClass: "mt-3 mb-2" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "ml-2 mt-1",
                          attrs: { value: "0", "unchecked-value": "1" },
                          model: {
                            value: _vm.status,
                            callback: function($$v) {
                              _vm.status = $$v
                            },
                            expression: "status"
                          }
                        },
                        [
                          _c("p", { staticClass: "ml-3 mb-0" }, [
                            _vm.status == 0
                              ? _c("span", [_vm._v("Active")])
                              : _vm._e(),
                            _vm.status != 0
                              ? _c("span", [_vm._v(" Inactive")])
                              : _vm._e()
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4",
                      attrs: { variant: "success", type: "submit", block: "" }
                    },
                    [
                      _vm._v("Edit "),
                      !_vm.$route.meta.is_resource
                        ? _c("span", [_vm._v(" AFE\n            Number")])
                        : _c("span", [_vm._v(" Cost Center")])
                    ]
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle mt-3"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [
                      _vm._v(
                        "Edit\n          Error. Cannot have duplicate AFES."
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "addService",
              attrs: {
                id: "addService",
                title: "Add Service",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                shown: function($event) {
                  return _vm.clearErrors()
                }
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "pb-1",
                  attrs: { "data-vv-scope": "addService" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.addServiceValidator("addService")
                    }
                  }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-1", attrs: { md: "12" } },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Service Name: ")
                          ]),
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "fa fa-hashtag" })
                                  ])
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "name",
                                  placeholder: "Service Name"
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name"
                                }
                              })
                            ],
                            1
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("addService.name"),
                                expression: "errors.has('addService.name')"
                              }
                            ],
                            staticClass: "fa fa-exclamation-triangle"
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("addService.name"),
                                  expression: "errors.has('addService.name')"
                                }
                              ]
                            },
                            [_vm._v("The service name is required.")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-1", attrs: { md: "12" } },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Service Code: ")
                          ]),
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "fa fa-hashtag" })
                                  ])
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "type",
                                  placeholder: "Service Code"
                                },
                                model: {
                                  value: _vm.type,
                                  callback: function($$v) {
                                    _vm.type = $$v
                                  },
                                  expression: "type"
                                }
                              })
                            ],
                            1
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("addService.type"),
                                expression: "errors.has('addService.type')"
                              }
                            ],
                            staticClass: "fa fa-exclamation-triangle"
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("addService.type"),
                                  expression: "errors.has('addService.type')"
                                }
                              ]
                            },
                            [_vm._v("The service code is required.")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-1", attrs: { md: "12" } },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "30px" } },
                            [
                              _c(
                                "b",
                                { staticStyle: { "margin-right": "5px" } },
                                [_vm._v("Uom is volume ? :")]
                              ),
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: { checked: _vm.uom_volume_status },
                                on: {
                                  click: function($event) {
                                    _vm.uom_volume_status = !_vm.uom_volume_status
                                  }
                                }
                              })
                            ]
                          ),
                          _c("span", [
                            _c(
                              "b",
                              { staticStyle: { "margin-right": "5px" } },
                              [_vm._v("Service is Flexible ? :")]
                            ),
                            _c("input", {
                              attrs: { type: "checkbox" },
                              domProps: { checked: _vm.isServiceFlexible },
                              on: {
                                click: function($event) {
                                  _vm.isServiceFlexible = !_vm.isServiceFlexible
                                }
                              }
                            })
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-1", attrs: { cols: "6" } },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("UOM: "),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "fa fa-question-circle",
                              attrs: { title: "Unit Of Measurement" }
                            })
                          ]),
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "fa fa-hashtag" })
                                  ])
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  disabled: _vm.isServiceFlexible,
                                  name: "uom1",
                                  placeholder: ""
                                },
                                model: {
                                  value: _vm.uom1,
                                  callback: function($$v) {
                                    _vm.uom1 = $$v
                                  },
                                  expression: "uom1"
                                }
                              })
                            ],
                            1
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("addService.uom1"),
                                expression: "errors.has('addService.uom1')"
                              }
                            ],
                            staticClass: "fa fa-exclamation-triangle"
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("addService.uom1"),
                                  expression: "errors.has('addService.uom1')"
                                }
                              ]
                            },
                            [_vm._v("The unit of measurement required.")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-1", attrs: { cols: "6" } },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Price / Unit: ")
                          ]),
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("i", {
                                      staticClass: "fa fa-dollar-sign"
                                    })
                                  ])
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "decimal:3",
                                    expression: "'decimal:3'"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  disabled: _vm.isServiceFlexible,
                                  name: "pu1",
                                  placeholder: ""
                                },
                                model: {
                                  value: _vm.pu1,
                                  callback: function($$v) {
                                    _vm.pu1 = $$v
                                  },
                                  expression: "pu1"
                                }
                              })
                            ],
                            1
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("addService.pu1"),
                                expression: "errors.has('addService.pu1')"
                              }
                            ],
                            staticClass: "fa fa-exclamation-triangle"
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("addService.pu1"),
                                  expression: "errors.has('addService.pu1')"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                "The price is required. Numbers only. Up to 3 decimals only."
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.uom2option === 0
                        ? _c(
                            "b-col",
                            { staticClass: "mb-1", attrs: { cols: "12" } },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn mt-2 float-right btn-success",
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      _vm.uom2option = 1
                                    }
                                  }
                                },
                                [_vm._v("Add Another\n              UOM")]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.uom2option === 1
                        ? _c(
                            "b-col",
                            { staticClass: "mb-1", attrs: { cols: "6" } },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("UOM 2: ")
                              ]),
                              _c(
                                "b-input-group",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-input-group-text", [
                                        _c("i", {
                                          staticClass: "fa fa-hashtag"
                                        })
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      name: "uom2",
                                      disabled: _vm.isServiceFlexible,
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.uom2,
                                      callback: function($$v) {
                                        _vm.uom2 = $$v
                                      },
                                      expression: "uom2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.uom2option === 1
                        ? _c(
                            "b-col",
                            { staticClass: "mb-1", attrs: { cols: "6" } },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Price / Unit: ")
                              ]),
                              _c(
                                "b-input-group",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-input-group-text", [
                                        _c("i", {
                                          staticClass: "fa fa-dollar-sign"
                                        })
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "decimal:3",
                                        expression: "'decimal:3'"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      name: "pu2",
                                      disabled: _vm.isServiceFlexible,
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.pu2,
                                      callback: function($$v) {
                                        _vm.pu2 = $$v
                                      },
                                      expression: "pu2"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("addService.pu2"),
                                    expression: "errors.has('addService.pu2')"
                                  }
                                ],
                                staticClass: "fa fa-exclamation-triangle"
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("addService.pu2"),
                                      expression: "errors.has('addService.pu2')"
                                    }
                                  ]
                                },
                                [_vm._v(" Numbers only. Ex: $59.99")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.uom2option === 1 && _vm.uom3option === 0
                        ? _c(
                            "b-col",
                            { staticClass: "mb-1", attrs: { cols: "12" } },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn mt-2 float-right btn-success",
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      _vm.uom3option = 1
                                    }
                                  }
                                },
                                [_vm._v("Add Another\n              UOM")]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.uom3option === 1
                        ? _c(
                            "b-col",
                            { staticClass: "mb-1", attrs: { cols: "6" } },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("UOM 3: ")
                              ]),
                              _c(
                                "b-input-group",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-input-group-text", [
                                        _c("i", {
                                          staticClass: "fa fa-hashtag"
                                        })
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      name: "uom3",
                                      disabled: _vm.isServiceFlexible,
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.uom3,
                                      callback: function($$v) {
                                        _vm.uom3 = $$v
                                      },
                                      expression: "uom3"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.uom3option === 1
                        ? _c(
                            "b-col",
                            { staticClass: "mb-1", attrs: { cols: "6" } },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Price / Unit: ")
                              ]),
                              _c(
                                "b-input-group",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-input-group-text", [
                                        _c("i", {
                                          staticClass: "fa fa-dollar-sign"
                                        })
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "decimal:3",
                                        expression: "'decimal:3'"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      disabled: _vm.isServiceFlexible,
                                      name: "pu3",
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.pu3,
                                      callback: function($$v) {
                                        _vm.pu3 = $$v
                                      },
                                      expression: "pu3"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("addService.pu3"),
                                    expression: "errors.has('addService.pu3')"
                                  }
                                ],
                                staticClass: "fa fa-exclamation-triangle"
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("addService.pu3"),
                                      expression: "errors.has('addService.pu3')"
                                    }
                                  ]
                                },
                                [
                                  _vm._v(
                                    "The price is required. Numbers only. Ex: $59.99"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-col",
                        { staticClass: "mb-2", attrs: { cols: "12" } },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Currency")
                          ]),
                          _c(
                            "b-input-group",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("i", {
                                      staticClass: "fa fa-dollar-sign"
                                    })
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-select",
                                {
                                  model: {
                                    value: _vm.currency,
                                    callback: function($$v) {
                                      _vm.currency = $$v
                                    },
                                    expression: "currency"
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "CAD", selected: "" } },
                                    [_vm._v("CAD")]
                                  ),
                                  _c("option", { attrs: { value: "USD" } }, [
                                    _vm._v("USD")
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("addService.currency"),
                                expression: "errors.has('addService.currency')"
                              }
                            ],
                            staticClass: "fa fa-exclamation-triangle"
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("addService.currency"),
                                  expression:
                                    "errors.has('addService.currency')"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                "The price is required. Numbers only. Ex: $59.99"
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                variant: "success",
                                type: "submit",
                                block: ""
                              }
                            },
                            [
                              _vm._v("Create Service "),
                              _c("i", { staticClass: "fa fa-plus" })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle mt-3"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [
                      _vm._v(
                        "Service error. Cannot have duplicates. Please contact admin."
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "assignService",
              attrs: {
                id: "assignService",
                title: "Assign Service",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                shown: function($event) {
                  return _vm.clearErrors()
                }
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "pb-2",
                  attrs: { "data-vv-scope": "assignService" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.assignServiceValidator("assignService")
                    }
                  }
                },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Select Vendor To Assign")
                  ]),
                  _c(
                    "b-row",
                    {},
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { md: "9" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                { staticStyle: { "z-index": "99" } },
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "fa fa-cog" })
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.vendorid,
                                      expression: "vendorid"
                                    }
                                  ],
                                  staticClass: "select-account form-control",
                                  attrs: { name: "vendorid" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.vendorid = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "0",
                                        disabled: "",
                                        selected: ""
                                      }
                                    },
                                    [_vm._v("Select Vendor")]
                                  ),
                                  _vm._l(_vm.wtsVendorList, function(option) {
                                    return _c(
                                      "option",
                                      {
                                        attrs: { for: "" },
                                        domProps: { value: option.vid }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(option.fname) +
                                            "\n                  " +
                                            _vm._s(option.lname) +
                                            " (" +
                                            _vm._s(option.company_name) +
                                            ")"
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                variant: "success",
                                type: "submit",
                                block: ""
                              }
                            },
                            [
                              _vm._v("Assign Service "),
                              _c("i", { staticClass: "fa fa-plus" })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle mt-1"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [
                      _vm._v(
                        "Service assignment error. Cannot have duplicates. Please contact admin."
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "addWssVendor",
              attrs: {
                id: "addWssVendor",
                title: "Add New Vendor Or Well Site Supervisor",
                "title-tag": "h2",
                centered: "",
                size: "lg",
                "hide-footer": ""
              },
              on: {
                shown: function($event) {
                  return _vm.clearErrors()
                },
                hidden: function($event) {
                  return _vm.loadData()
                }
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "pb-2",
                  attrs: { "data-vv-scope": "addWssVendor" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.addWssVendor("addWssVendor")
                    }
                  }
                },
                [
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(
                      "Enter email address to invite to project. If well site supervisor is not registered you\n          must invite them again for them to appear in your project lists."
                    )
                  ]),
                  _c(
                    "b-input-group",
                    { staticClass: "mt-3 mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        [_c("b-input-group-text", [_vm._v("@")])],
                        1
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|email",
                            expression: "'required|email'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "Email",
                          name: "email",
                          autocomplete: "email"
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("addWssVendor.email"),
                        expression: "errors.has('addWssVendor.email')"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("addWssVendor.email"),
                          expression: "errors.has('addWssVendor.email')"
                        }
                      ],
                      staticClass: "help is-danger"
                    },
                    [_vm._v(_vm._s(_vm.errors.first("addWssVendor.email")))]
                  ),
                  _c(
                    "b-form-group",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-form-radio-group",
                        {
                          attrs: {
                            stacked: "",
                            id: "radios2",
                            name: "radioSubComponent"
                          },
                          model: {
                            value: _vm.status,
                            callback: function($$v) {
                              _vm.status = $$v
                            },
                            expression: "status"
                          }
                        },
                        [
                          _c("b-form-radio", { attrs: { value: "0" } }, [
                            _vm._v("Vendor")
                          ]),
                          _c("b-form-radio", { attrs: { value: "1" } }, [
                            _vm._v("WSS")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error"
                      }
                    ],
                    staticClass: "fa fa-exclamation-triangle"
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "mb-3 help is-danger"
                    },
                    [
                      _vm._v(
                        "Error. Email already exists. Please contact admin."
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4",
                      attrs: { variant: "success", type: "submit", block: "" }
                    },
                    [_vm._v("Add Email")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "importVendors",
              attrs: {
                id: "importVendors",
                "hide-footer": "",
                title: "Import Vendors",
                "title-tag": "h2",
                centered: "",
                size: "lg"
              },
              on: {
                shown: function($event) {
                  ;(_vm.importVendorsList = []),
                    (_vm.selectedVendorList = []),
                    (_vm.selectedServicesList = []),
                    (_vm.projectIdForImport = null),
                    (_vm.allSelectedService = []),
                    (_vm.indeterminateService = []),
                    (_vm.allSelected = false),
                    (_vm.ndeterminate = false),
                    (_vm.importVendorsButton = false)
                },
                hidden: function($event) {
                  ;(_vm.importVendorsList = []),
                    (_vm.selectedVendorList = []),
                    (_vm.selectedServicesList = []),
                    (_vm.projectIdForImport = null),
                    (_vm.progressBar = false),
                    (_vm.allSelectedService = []),
                    (_vm.indeterminateService = []),
                    (_vm.allSelected = false),
                    (_vm.ndeterminate = false),
                    (_vm.importVendorsButton = false)
                }
              }
            },
            [
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { md: "12" } },
                    [
                      _c("h5", [_vm._v("Select Project")]),
                      _c("multiselect", {
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "Search For Project",
                          "tag-placeholder": "Search For Project",
                          "track-by": "projectid",
                          options: _vm.projectData,
                          multiple: false,
                          "close-on-select": true,
                          "clear-on-select": true,
                          "preserve-search": true,
                          taggable: false,
                          label: "projectname",
                          "preselect-first": false
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadVendorsAndServices()
                          }
                        },
                        model: {
                          value: _vm.projectIdForImport,
                          callback: function($$v) {
                            _vm.projectIdForImport = $$v
                          },
                          expression: "projectIdForImport"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.importVendorsList.length,
                          expression: "importVendorsList.length"
                        }
                      ],
                      attrs: { md: "12" }
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "h5 label-width",
                          attrs: {
                            indeterminate: _vm.indeterminate,
                            "aria-controls": "importVendorsList"
                          },
                          on: { change: _vm.toggleAll },
                          model: {
                            value: _vm.allSelected,
                            callback: function($$v) {
                              _vm.allSelected = $$v
                            },
                            expression: "allSelected"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.allSelected
                                    ? "Un-select All"
                                    : "Select All"
                                ) +
                                " "
                            )
                          ])
                        ]
                      ),
                      _vm._l(_vm.importVendorsList, function(vendor) {
                        return _c(
                          "div",
                          { staticClass: "ml-4" },
                          [
                            _c("b-form-checkbox", {
                              key: vendor.value,
                              staticClass: "label-width checkBox",
                              attrs: { value: vendor.value },
                              nativeOn: {
                                change: function($event) {
                                  return _vm.deselectServices(
                                    $event,
                                    vendor.value
                                  )
                                }
                              },
                              model: {
                                value: _vm.selectedVendorList,
                                callback: function($$v) {
                                  _vm.selectedVendorList = $$v
                                },
                                expression: "selectedVendorList"
                              }
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(vendor.text) +
                                "\n            "
                            ),
                            _vm.check(vendor.value, _vm.selectedVendorList)
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle",
                                            value: "col" + vendor.value,
                                            expression: "'col'+vendor.value"
                                          }
                                        ],
                                        attrs: { variant: "link" }
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v("Select Services")
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        staticClass: "mt-2",
                                        attrs: { id: "col" + vendor.value }
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "label-width",
                                                attrs: {
                                                  indeterminate:
                                                    _vm.indeterminateService[
                                                      vendor.value
                                                    ]
                                                },
                                                nativeOn: {
                                                  change: function($event) {
                                                    return _vm.toggleAllServices(
                                                      $event,
                                                      vendor.value
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.allSelectedService[
                                                      vendor.value
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.allSelectedService,
                                                      vendor.value,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "allSelectedService[vendor.value]"
                                                }
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.allSelectedService[
                                                          vendor.value
                                                        ]
                                                          ? "Un-select All Services"
                                                          : "Select All Services"
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ])
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.vendorServiceIndex[
                                                vendor.value
                                              ],
                                              function(service) {
                                                return _c(
                                                  "b-form-checkbox",
                                                  {
                                                    key: service.value,
                                                    staticClass:
                                                      "col-5 label-width",
                                                    attrs: {
                                                      value: service.value,
                                                      inline: ""
                                                    },
                                                    nativeOn: {
                                                      change: function($event) {
                                                        return _vm.setAllSelctedServicesState(
                                                          vendor.value
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .selectedServicesList[
                                                          vendor.value
                                                        ],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedServicesList,
                                                          vendor.value,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedServicesList[vendor.value]"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(service.text) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      _c(
                        "b-col",
                        { attrs: { md: "12 mt-4" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "success",
                                disabled: _vm.importVendorsButton,
                                block: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.postImportVendorsServices()
                                }
                              }
                            },
                            [
                              _vm._v("\n              Import Vendors "),
                              _c("i", { staticClass: "fa fa-file-import" })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }