var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c("b-col", { attrs: { md: "12 clearfix mt-3" } }, [
            _vm.name
              ? _c("div", [
                  _c("h4", { staticClass: "d-inline" }, [
                    _vm._v("Assign Services To: " + _vm._s(_vm.name) + " "),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "ml-2 fa fa fa-question-circle",
                      attrs: {
                        title:
                          "To disable vendor from project make all services inactive"
                      }
                    })
                  ])
                ])
              : _vm._e(),
            _vm.name
              ? _c(
                  "div",
                  {
                    staticClass: "clearfix mt-3",
                    staticStyle: { width: "max-content" }
                  },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.exportService",
                            modifiers: { exportService: true }
                          }
                        ],
                        staticClass: "pull-right btn-sm",
                        attrs: { variant: "success" }
                      },
                      [
                        _vm._v(" Export Services To Existing Project "),
                        _c("i", { staticClass: "fa fa-plus" })
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.copyService",
                            modifiers: { copyService: true }
                          }
                        ],
                        staticClass: "pull-right mr-2 btn-sm",
                        attrs: { variant: "success" }
                      },
                      [
                        _vm._v(" Import Services From Existing Project "),
                        _c("i", { staticClass: "fa fa-plus" })
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.import",
                            modifiers: { import: true }
                          }
                        ],
                        staticClass: "pull-right mr-2 btn-sm",
                        attrs: { variant: "success" }
                      },
                      [
                        _vm._v(" Import Services From CSV "),
                        _c("i", { staticClass: "fa fa-plus" })
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.addService",
                            modifiers: { addService: true }
                          }
                        ],
                        staticClass: "pull-right mr-2 btn-sm",
                        attrs: { variant: "success" }
                      },
                      [
                        _vm._v(" Add Service "),
                        _c("i", { staticClass: "fa fa-plus" })
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm.name
        ? _c("v-client-table", {
            ref: "Servicedatatable",
            staticClass: "statusTable",
            attrs: {
              columns: _vm.columnsServices,
              data: _vm.wtsVendorServiceData,
              options: _vm.optionsServices,
              theme: _vm.theme,
              id: "dataTable"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "status",
                  fn: function(props) {
                    return _c("div", {}, [
                      props.row.status === 0
                        ? _c("span", [
                            _c(
                              "button",
                              { staticClass: "btn btn-sm btn-success w-100" },
                              [_vm._v("Active")]
                            )
                          ])
                        : _vm._e(),
                      props.row.status !== 0
                        ? _c("span", [
                            _c(
                              "button",
                              { staticClass: "btn btn-sm btn-danger w-100" },
                              [_vm._v("Inactive")]
                            )
                          ])
                        : _vm._e()
                    ])
                  }
                },
                {
                  key: "uom1",
                  fn: function(props) {
                    return _c("div", {}, [
                      props.row.isflexible !== "Y"
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(props.row.pu1) +
                                "/" +
                                _vm._s(props.row.uom1)
                            )
                          ])
                        : _vm._e()
                    ])
                  }
                },
                {
                  key: "volume",
                  fn: function(props) {
                    return _c("div", {}, [
                      _vm._v("\n     " + _vm._s(props.row.volume) + "\n     ")
                    ])
                  }
                },
                {
                  key: "isflexible",
                  fn: function(props) {
                    return _c("div", {}, [
                      _vm._v(
                        "\n     " + _vm._s(props.row.isflexible) + "\n     "
                      )
                    ])
                  }
                },
                {
                  key: "uom2",
                  fn: function(props) {
                    return _c("div", {}, [
                      props.row.pu2
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(props.row.pu2) +
                                "/" +
                                _vm._s(props.row.uom2)
                            )
                          ])
                        : _vm._e()
                    ])
                  }
                },
                {
                  key: "uom3",
                  fn: function(props) {
                    return _c("div", {}, [
                      props.row.pu3
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(props.row.pu3) +
                                "/" +
                                _vm._s(props.row.uom3)
                            )
                          ])
                        : _vm._e()
                    ])
                  }
                },
                {
                  key: "actions",
                  fn: function(props) {
                    return _c(
                      "div",
                      {},
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.editService",
                                modifiers: { editService: true }
                              }
                            ],
                            staticClass: "mr-2 btn-sm",
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                props.row.volume == "N"
                                  ? (_vm.uom_volume_status = false)
                                  : (_vm.uom_volume_status = true)
                                props.row.isflexible == "Y"
                                  ? (_vm.isServiceFlexible = true)
                                  : (_vm.isServiceFlexible = false)
                                _vm.sendEditService(props.row)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-edit" })]
                        )
                      ],
                      1
                    )
                  }
                },
                {
                  key: "delete",
                  fn: function(props) {
                    return _c(
                      "div",
                      {},
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2 btn-sm",
                            attrs: { variant: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.sendDeleteService(props.row)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-trash" })]
                        )
                      ],
                      1
                    )
                  }
                }
              ],
              null,
              false,
              3860247250
            )
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "editService",
          attrs: {
            id: "editService",
            title: "Edit Service",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              attrs: { "data-vv-scope": "editService" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.editServiceValidator("editService")
                }
              }
            },
            [
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-1", attrs: { md: "12" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Service Name:")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-hashtag" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "servicename",
                              placeholder: "Service Name"
                            },
                            model: {
                              value: _vm.servicename,
                              callback: function($$v) {
                                _vm.servicename = $$v
                              },
                              expression: "servicename"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("editService.servicename"),
                            expression: "errors.has('editService.servicename')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("editService.servicename"),
                              expression:
                                "errors.has('editService.servicename')"
                            }
                          ]
                        },
                        [_vm._v("The service name is required.")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-1", attrs: { md: "12" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Service Code:")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-hashtag" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "type",
                              placeholder: "Service Code"
                            },
                            model: {
                              value: _vm.type,
                              callback: function($$v) {
                                _vm.type = $$v
                              },
                              expression: "type"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("editService.type"),
                            expression: "errors.has('editService.type')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("editService.type"),
                              expression: "errors.has('editService.type')"
                            }
                          ]
                        },
                        [_vm._v("The service code is required.")]
                      )
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "mb-1", attrs: { md: "12" } }, [
                    _c("span", { staticStyle: { "margin-right": "30px" } }, [
                      _c("b", { staticStyle: { "margin-right": "5px" } }, [
                        _vm._v("Uom is volume ? :")
                      ]),
                      _c("input", {
                        attrs: { type: "checkbox" },
                        domProps: { checked: _vm.uom_volume_status },
                        on: {
                          click: function($event) {
                            _vm.uom_volume_status = !_vm.uom_volume_status
                          }
                        }
                      })
                    ])
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "mb-1", attrs: { cols: "6" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("UOM: "),
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "fa fa-question-circle",
                          attrs: { title: "Unit Of Measurement" }
                        })
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-hashtag" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "uom1",
                              disabled: _vm.isServiceFlexible,
                              placeholder: ""
                            },
                            model: {
                              value: _vm.uom1,
                              callback: function($$v) {
                                _vm.uom1 = $$v
                              },
                              expression: "uom1"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("editService.uom1"),
                            expression: "errors.has('editService.uom1')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("editService.uom1"),
                              expression: "errors.has('editService.uom1')"
                            }
                          ]
                        },
                        [_vm._v("The unit of measurement required.")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-1", attrs: { cols: "6" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Price / Unit:")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-dollar-sign" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "decimal:3",
                                expression: "'decimal:3'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              disabled: _vm.isServiceFlexible,
                              name: "pu1",
                              placeholder: ""
                            },
                            model: {
                              value: _vm.pu1,
                              callback: function($$v) {
                                _vm.pu1 = $$v
                              },
                              expression: "pu1"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("editService.pu1"),
                            expression: "errors.has('editService.pu1')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("editService.pu1"),
                              expression: "errors.has('editService.pu1')"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            "The price is required. Numbers only. Ex: $59.99"
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-1", attrs: { cols: "6" } },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("UOM 2: ")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-hashtag" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "uom2",
                              disabled: _vm.isServiceFlexible,
                              placeholder: ""
                            },
                            model: {
                              value: _vm.uom2,
                              callback: function($$v) {
                                _vm.uom2 = $$v
                              },
                              expression: "uom2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-1", attrs: { cols: "6" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Price / Unit: ")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-dollar-sign" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "decimal:3",
                                expression: "'decimal:3'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              disabled: _vm.isServiceFlexible,
                              name: "pu2",
                              placeholder: ""
                            },
                            model: {
                              value: _vm.pu2,
                              callback: function($$v) {
                                _vm.pu2 = $$v
                              },
                              expression: "pu2"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("editService.pu2"),
                            expression: "errors.has('editService.pu2')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("editService.pu2"),
                              expression: "errors.has('editService.pu2')"
                            }
                          ]
                        },
                        [_vm._v(" Numbers only. Ex: $59.99")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-1", attrs: { cols: "6" } },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("UOM 3: ")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-hashtag" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "uom3",
                              disabled: _vm.isServiceFlexible,
                              placeholder: ""
                            },
                            model: {
                              value: _vm.uom3,
                              callback: function($$v) {
                                _vm.uom3 = $$v
                              },
                              expression: "uom3"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-1", attrs: { cols: "6" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Price / Unit: ")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-dollar-sign" })
                              ])
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "decimal:3",
                                expression: "'decimal:3'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              disabled: _vm.isServiceFlexible,
                              name: "pu3",
                              placeholder: ""
                            },
                            model: {
                              value: _vm.pu3,
                              callback: function($$v) {
                                _vm.pu3 = $$v
                              },
                              expression: "pu3"
                            }
                          })
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("editService.pu3"),
                            expression: "errors.has('editService.pu3')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("editService.pu3"),
                              expression: "errors.has('editService.pu3')"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            "The price is required. Numbers only. Ex: $59.99"
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Currency")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-dollar-sign" })
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-select",
                            {
                              model: {
                                value: _vm.currency,
                                callback: function($$v) {
                                  _vm.currency = $$v
                                },
                                expression: "currency"
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "CAD", selected: "" } },
                                [_vm._v("CAD")]
                              ),
                              _c("option", { attrs: { value: "USD" } }, [
                                _vm._v("USD")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("editService.currency"),
                            expression: "errors.has('editService.currency')"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("editService.currency"),
                              expression: "errors.has('editService.currency')"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            "The price is required. Numbers only. Ex: $59.99"
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-2 mt-2", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "ml-2 mt-1",
                              attrs: { value: "0", "unchecked-value": "1" },
                              model: {
                                value: _vm.status,
                                callback: function($$v) {
                                  _vm.status = $$v
                                },
                                expression: "status"
                              }
                            },
                            [
                              _c("p", { staticClass: "ml-3 mb-0" }, [
                                _vm.status == 0
                                  ? _c("span", [_vm._v("Active")])
                                  : _vm._e(),
                                _vm.status != 0
                                  ? _c("span", [_vm._v(" Inactive")])
                                  : _vm._e()
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "my-1 mb-2",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.error,
                            expression: "error"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle mt-2"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.error,
                              expression: "error"
                            }
                          ],
                          staticClass: "help is-danger"
                        },
                        [
                          _vm._v(
                            "Service error. Cannot have duplicates. Please contact admin."
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            variant: "success",
                            type: "submit",
                            block: ""
                          }
                        },
                        [
                          _vm._v("Edit Service "),
                          _c("i", { staticClass: "fa fa-edit" })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "copyService",
          attrs: {
            id: "copyService",
            title: "Import Services",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          },
          on: {
            shown: function($event) {
              _vm.loadProjectsForImport(),
                (_vm.project_id = []),
                (_vm.successServices = []),
                (_vm.serviceErrors = []),
                (_vm.loadingCount = 0),
                (_vm.progressBarServicesCount = 0),
                (_vm.progressBar = false),
                (_vm.importing = true),
                (_vm.projectid = []),
                (_vm.vendorSelection = [])
            },
            hidden: function($event) {
              _vm.loadData(), (_vm.importing = false), (_vm.progressBar = false)
            }
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { md: "12" } },
                [
                  _c("h5", [_vm._v("Select Project")]),
                  _c("multiselect", {
                    staticClass: "w-100",
                    attrs: {
                      placeholder: "Search For Project",
                      "tag-placeholder": "Search For Project",
                      "track-by": "projectid",
                      options: _vm.projectData,
                      multiple: true,
                      "close-on-select": true,
                      "clear-on-select": true,
                      "preserve-search": true,
                      taggable: false,
                      label: "projectname",
                      "preselect-first": false
                    },
                    model: {
                      value: _vm.project_id,
                      callback: function($$v) {
                        _vm.project_id = $$v
                      },
                      expression: "project_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "success",
                        disabled: _vm.progressBar,
                        type: "submit",
                        block: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.CopyServiceForSelectedProject()
                        }
                      }
                    },
                    [
                      _vm._v("Import Services "),
                      _c("i", { staticClass: "fa fa-file-import" })
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.progressBar,
                      expression: "progressBar"
                    }
                  ],
                  staticClass: "col-md-12 mt-4"
                },
                [_c("h4", [_vm._v("Progress:")])]
              ),
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "b-progress",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.progressBar,
                          expression: "progressBar"
                        }
                      ],
                      staticClass: "mt-2",
                      staticStyle: { "font-size": "0.8rem" },
                      attrs: {
                        max: _vm.progressBarServicesCount,
                        height: "2rem",
                        "show-value": ""
                      }
                    },
                    [
                      _vm.loadingCount == _vm.progressBarServicesCount
                        ? _c("b-progress-bar", {
                            attrs: {
                              value: _vm.loadingCount,
                              label:
                                _vm.loadingCount +
                                "/" +
                                _vm.progressBarServicesCount +
                                " (100%)",
                              variant: "success"
                            }
                          })
                        : _c("b-progress-bar", {
                            attrs: {
                              value: _vm.loadingCount,
                              label:
                                _vm.loadingCount +
                                "/" +
                                _vm.progressBarServicesCount +
                                " (" +
                                (
                                  (_vm.loadingCount /
                                    _vm.progressBarServicesCount) *
                                  100
                                ).toFixed(2) +
                                "%" +
                                ")",
                              variant: "success"
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.progressBar,
                          expression: "progressBar"
                        }
                      ],
                      staticClass: "mt-3"
                    },
                    [
                      _vm._v(
                        "Please do not close this window until 100% or it may be unsuccessful."
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.successServices.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "col-md-12 mt-2",
                      staticStyle: { "max-height": "300px", overflow: "scroll" }
                    },
                    [
                      _c("h5", [_vm._v("Successful: ")]),
                      _c(
                        "ul",
                        _vm._l(_vm.successServices, function(success) {
                          return _c("li", [
                            _vm._v(
                              "\n             " +
                                _vm._s(success) +
                                "\n           "
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e(),
              _vm.updatedService.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "col-md-12 mt-2",
                      staticStyle: { "max-height": "300px", overflow: "scroll" }
                    },
                    [
                      _c("h5", [_vm._v("Updated Services ")]),
                      _c(
                        "ul",
                        _vm._l(_vm.updatedService, function(value) {
                          return _c("li", [
                            _vm._v(
                              "\n             " +
                                _vm._s(value) +
                                "\n           "
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e(),
              _vm.serviceErrors.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "col-md-12 mt-2",
                      staticStyle: { "max-height": "300px", overflow: "scroll" }
                    },
                    [
                      _c("h5", [
                        _vm._v("Unsuccessful (Possible Duplicates): ")
                      ]),
                      _c(
                        "ul",
                        _vm._l(_vm.serviceErrors, function(error) {
                          return _c("li", [
                            _vm._v(
                              "\n             " +
                                _vm._s(error) +
                                "\n           "
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "exportService",
          attrs: {
            id: "exportService",
            title: "Export Services",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          },
          on: {
            shown: function($event) {
              _vm.loadProjectsForImport(),
                (_vm.successServices = []),
                (_vm.project_id = []),
                (_vm.serviceErrors = []),
                (_vm.projectid = []),
                (_vm.vendorSelection = []),
                (_vm.loadingCount = 0),
                (_vm.progressBarServicesCount = 0),
                (_vm.progressBar = false)
            },
            hidden: function($event) {
              _vm.loadData(), (_vm.progressBar = false)
            }
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { md: "12" } },
                [
                  _c("h5", [_vm._v("Select Project")]),
                  _c("multiselect", {
                    staticClass: "w-100",
                    attrs: {
                      placeholder: "Search For Project",
                      "tag-placeholder": "Search For Project",
                      "track-by": "projectid",
                      options: _vm.projectData,
                      multiple: true,
                      "close-on-select": true,
                      "clear-on-select": true,
                      "preserve-search": true,
                      taggable: false,
                      label: "projectname",
                      "preselect-first": false
                    },
                    model: {
                      value: _vm.project_id,
                      callback: function($$v) {
                        _vm.project_id = $$v
                      },
                      expression: "project_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "success",
                        disabled: _vm.progressBar,
                        type: "submit",
                        block: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportServicesForMultipleProjecs()
                        }
                      }
                    },
                    [
                      _vm._v("Export Services "),
                      _c("i", { staticClass: "fa fa-file-import" })
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.progressBar,
                      expression: "progressBar"
                    }
                  ],
                  staticClass: "col-md-12 mt-4"
                },
                [_c("h4", [_vm._v("Progress:")])]
              ),
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "b-progress",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.progressBar,
                          expression: "progressBar"
                        }
                      ],
                      staticClass: "mt-2",
                      staticStyle: { "font-size": "0.8rem" },
                      attrs: {
                        max: _vm.progressBarServicesCount,
                        height: "2rem",
                        "show-value": ""
                      }
                    },
                    [
                      _vm.loadingCount == _vm.progressBarServicesCount
                        ? _c("b-progress-bar", {
                            attrs: {
                              value: _vm.loadingCount,
                              label:
                                _vm.loadingCount +
                                "/" +
                                _vm.progressBarServicesCount +
                                " (100%)",
                              variant: "success"
                            }
                          })
                        : _c("b-progress-bar", {
                            attrs: {
                              value: _vm.loadingCount,
                              label:
                                _vm.loadingCount +
                                "/" +
                                _vm.progressBarServicesCount +
                                " (" +
                                (
                                  (_vm.loadingCount /
                                    _vm.progressBarServicesCount) *
                                  100
                                ).toFixed(2) +
                                "%" +
                                ")",
                              variant: "success"
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.progressBar,
                          expression: "progressBar"
                        }
                      ],
                      staticClass: "mt-3"
                    },
                    [
                      _vm._v(
                        "Please do not close this window until 100% or it may be unsuccessful."
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.successServices.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "col-md-12 mt-2",
                      staticStyle: { "max-height": "300px", overflow: "scroll" }
                    },
                    [
                      _c("h5", [_vm._v("Successful: ")]),
                      _c(
                        "ul",
                        _vm._l(_vm.successServices, function(success) {
                          return _c("li", [
                            _vm._v(
                              "\n             " +
                                _vm._s(success) +
                                "\n           "
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e(),
              _vm.updatedService.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "col-md-12 mt-2",
                      staticStyle: { "max-height": "300px", overflow: "scroll" }
                    },
                    [
                      _c("h5", [_vm._v("Updated Services ")]),
                      _c(
                        "ul",
                        _vm._l(_vm.updatedService, function(value) {
                          return _c("li", [
                            _vm._v(
                              "\n             " +
                                _vm._s(value) +
                                "\n           "
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e(),
              _vm.serviceErrors.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "col-md-12 mt-2",
                      staticStyle: { "max-height": "300px", overflow: "scroll" }
                    },
                    [
                      _c("h5", [
                        _vm._v("Unsuccessful (Possible Duplicates): ")
                      ]),
                      _c(
                        "ul",
                        _vm._l(_vm.serviceErrors, function(error) {
                          return _c("li", [
                            _vm._v(
                              "\n             " +
                                _vm._s(error) +
                                "\n           "
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "import",
          attrs: {
            id: "import",
            "hide-footer": "",
            title: "Import Services",
            "title-tag": "h2",
            centered: "",
            size: "lg"
          },
          on: {
            shown: function($event) {
              _vm.clearImport(),
                (_vm.loadingCount = 0),
                (_vm.progressBarServicesCount = 0),
                (_vm.progressBar = false)
            },
            hidden: function($event) {
              _vm.loadData(), (_vm.progressBar = false)
            }
          }
        },
        [
          _c("div", { staticClass: "parse mt-2 mb-4 row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("h5", [_vm._v("CSV Files Only")]),
              _c("input", {
                ref: "csvFile",
                staticClass: "mt-2",
                attrs: { id: "fileInput", type: "file" },
                on: { change: _vm.upload }
              })
            ]),
            _vm.doc.length > 0
              ? _c("div", { staticClass: "col-md-6" }, [
                  _c("h5", [_vm._v("Import")]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.importServices(_vm.doc)
                        }
                      }
                    },
                    [_vm._v("Import")]
                  )
                ])
              : _vm._e(),
            _vm.doc.length === 0
              ? _c("div", { staticClass: "col-md-6" }, [
                  _c("h5", [_vm._v("Template")]),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: "/templates/Service_template.csv" }
                    },
                    [
                      _vm._v("Download Template "),
                      _c("i", { staticClass: "fa fa-file" })
                    ]
                  )
                ])
              : _vm._e(),
            _c("br"),
            _c("div", { staticClass: "ml-3 mt-3 md-2" }, [
              _c("span", [_c("b", [_vm._v("Note:")])]),
              _c("br"),
              _c("span", [
                _vm._v(
                  "1. Remove White spaces from column headers from csv file."
                )
              ]),
              _c("br"),
              _c("span", [
                _vm._v(
                  "2. Columns Name should we same as mention in download template."
                )
              ]),
              _c("br"),
              _c("span", [
                _vm._v("3. Only use ' "),
                _c("b", [_vm._v("$")]),
                _vm._v(" ' and ' "),
                _c("b", [_vm._v(",")]),
                _vm._v(" ' with service prices.")
              ]),
              _c("br"),
              _c("span", [
                _vm._v("4. Only use ' "),
                _c("b", [_vm._v("CAD")]),
                _vm._v(" ' and ' "),
                _c("b", [_vm._v("USD")]),
                _vm._v(" ' with service prices.")
              ]),
              _c("br")
            ]),
            _c(
              "div",
              { staticClass: "col-md-12 mt-2" },
              [
                _c(
                  "b-progress",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.progressBar,
                        expression: "progressBar"
                      }
                    ],
                    staticClass: "mt-2",
                    staticStyle: { "font-size": "0.8rem" },
                    attrs: {
                      max: _vm.progressBarServicesCount,
                      height: "2rem",
                      "show-value": ""
                    }
                  },
                  [
                    _vm.loadingCount == _vm.progressBarServicesCount
                      ? _c("b-progress-bar", {
                          attrs: {
                            value: _vm.loadingCount,
                            label:
                              _vm.loadingCount +
                              "/" +
                              _vm.progressBarServicesCount +
                              " (100%)",
                            variant: "success"
                          }
                        })
                      : _c("b-progress-bar", {
                          attrs: {
                            value: _vm.loadingCount,
                            label:
                              _vm.loadingCount +
                              "/" +
                              _vm.progressBarServicesCount +
                              " (" +
                              (
                                (_vm.loadingCount /
                                  _vm.progressBarServicesCount) *
                                100
                              ).toFixed(2) +
                              "%" +
                              ")",
                            variant: "success"
                          }
                        })
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.progressBar,
                        expression: "progressBar"
                      }
                    ],
                    staticClass: "mt-2"
                  },
                  [
                    _vm._v(
                      "Please do not close this window until 100% or it may be unsuccessful."
                    )
                  ]
                )
              ],
              1
            ),
            _vm.successServices.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "col-md-12 mt-2",
                    staticStyle: { "max-height": "300px", overflow: "scroll" }
                  },
                  [
                    _c("h5", [_vm._v("Successful: ")]),
                    _c(
                      "ul",
                      _vm._l(_vm.successServices, function(success) {
                        return _c("li", [
                          _vm._v(
                            "\n             " +
                              _vm._s(success) +
                              "\n           "
                          )
                        ])
                      }),
                      0
                    )
                  ]
                )
              : _vm._e(),
            _vm.updatedService.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "col-md-12 mt-2",
                    staticStyle: { "max-height": "300px", overflow: "scroll" }
                  },
                  [
                    _c("h5", [_vm._v("Updated Services ")]),
                    _c(
                      "ul",
                      _vm._l(_vm.updatedService, function(value) {
                        return _c("li", [
                          _vm._v(
                            "\n             " + _vm._s(value) + "\n           "
                          )
                        ])
                      }),
                      0
                    )
                  ]
                )
              : _vm._e(),
            _vm.serviceErrors.length > 0 || _vm.errorDoc.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "col-md-12 mt-2",
                    staticStyle: { "max-height": "300px", overflow: "scroll" }
                  },
                  [
                    _vm.serviceErrors.length > 0
                      ? _c("h5", [
                          _vm._v("Unsuccessful (Duplicate Services): ")
                        ])
                      : _vm._e(),
                    _vm.serviceErrors.length > 0
                      ? _c(
                          "ul",
                          _vm._l(_vm.serviceErrors, function(error) {
                            return _c("li", [
                              _vm._v(
                                "\n             " +
                                  _vm._s(error) +
                                  "\n           "
                              )
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.errorDoc.length > 0
                      ? _c("h5", [
                          _vm._v("Unsuccessful (Services are not valid): ")
                        ])
                      : _vm._e(),
                    _vm.errorDoc.length > 0
                      ? _c(
                          "ul",
                          _vm._l(_vm.errorDoc, function(error) {
                            return _c("li", [
                              _vm._v(
                                "\n             " +
                                  _vm._s(
                                    error.msg
                                      ? error.value.Service + ": " + error.msg
                                      : error.Service
                                  ) +
                                  "\n           "
                              )
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }